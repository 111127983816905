import React from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { RecapItem } from 'components/paymentSuccessful'

const CurrentSubscription = ({
  isOwner,
}) => {
  const { data, loading } = useQuery(SUBSCRIPTION)

  if (loading) {
    return null
  }

  const {
    quantity,
    plan,
  } = data.me.subscription

  return (
    <Wrapper >
      <Title >
        MON ABONNEMENT
      </Title>
      <Description >
        <LicenceTypeAndCount >
          {`Abonnement ${plan.title}${
            quantity > 1
              && isOwner
              ? ` - ${quantity} licences`
              : ''
          }`
          }
        </LicenceTypeAndCount>
        {
          isOwner &&
          <>
            <Pricing >
              {`${plan.metadata.displayPrice}€ ${plan.metadata.taxType} / ${plan.interval === 'year' ? 'an' : 'mois' } ${quantity > 1 ? 'par licence' : ''}`}
            </Pricing>
            <Recap >
              <RecapItem >
                { plan.interval === 'year' ? 'Abonnement annuel' : 'Abonnement mensuel' }
              </RecapItem>
              { plan.metadata.threshold > 1 && (
                <RecapItem >
                  <span>Vous souhaitez augmenter ou diminuer votre nombre de licences ? Contactez-nous sur </span>
                  <a href={ `mailto:${process.env.REACT_APP_CONTACT_ADDRESS}` }>{process.env.REACT_APP_CONTACT_ADDRESS}</a>
                </RecapItem>
                )
              }
              { plan.metadata.threshold === 1 && (
                <RecapItem >
                  1 licence
                </RecapItem>
                )
              }
            </Recap>
          </>
        }
      </Description>
    </Wrapper>
  )
}

CurrentSubscription.propTypes = {
  isOwner: PropTypes.bool.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = styled.div `
  padding: 1.5rem;
  margin: 0.5rem 2rem 3rem 0;
  border: solid 0.0625rem rgb(22 50 98 / 8%);
  box-shadow: 0.375rem 0.125rem 2.5rem 0 rgb(6 38 79 / 16%);
`

const Recap = styled.div `
  padding: 1rem 0 0;
`

const Title = styled.span`
  margin-bottom: 1rem;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.darkGray};
  opacity: 0.5;
`

const LicenceTypeAndCount = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  color: ${colors.midnightBlue};
`

const Pricing = styled.span`
  margin-bottom: 1.2rem;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.midnightBlue};
`

export default CurrentSubscription
