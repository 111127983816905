import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import Separator from './Separator'
import { ModalContext } from '../../context/Modal'
import { colors } from 'theme'
import FigureItem from 'components/FigureItem'
import { Button } from 'components/atoms'
import ModalDailyQuestion from 'components/ModalDailyQuestion'
import { DAILY_QUESTION } from 'graphql/queries/dailyQuestion'

const alt = 'aperçu application'

const PresentationContent = () => {
  const location = useLocation()
  const { actions: { toggleDailyQuestionModal }} = useContext(ModalContext)
  const dailyQuestionRef = useRef(null)

  const {
    data: dailyQuestionData,
    loading: dailyQuestionLoading,
  } = useQuery(DAILY_QUESTION, {
    onCompleted: () => {
      if (location.hash !== '#testez-vous') {
        return
      }

      const scrollTarget = dailyQuestionRef.current.getBoundingClientRect().top - 100
      window.scrollTo({
        top: scrollTarget,
        behavior: 'smooth',
      })
    },
  })

  return (
    <>
      { !dailyQuestionLoading && dailyQuestionData?.dailyQuestion != null &&
        <ModalDailyQuestion
          dailyQuestion={ dailyQuestionData.dailyQuestion }
        />
      }
      <Wrapper id="presentation">
        <InnerWrapper>
          { !dailyQuestionLoading && dailyQuestionData?.dailyQuestion != null &&
            <DailyQuestionWrapper >
              <DailyQuestion>
                <Title ref={ dailyQuestionRef }>Testez-vous</Title>
                <DailyQuestionContent
                  dangerouslySetInnerHTML={ {
                    '__html': dailyQuestionData.dailyQuestion.title,
                  } }
                />
                <Button
                  size="medium"
                  variant="primary"
                  onClick={ toggleDailyQuestionModal }
                >
                  Voir la réponse
                </Button>
              </DailyQuestion>
            </DailyQuestionWrapper>
          }
          <InfoList>
            { infoList.map((info, i) =>
              <FigureItem
                key={ i }
                { ...info }
              />,
            )}
          </InfoList>
        </InnerWrapper>
        <Separator />
        <Header>
          <Title>
            {'Gagnez du temps : choisissez Défimédoc, l\'appli qui vous forme et vous informe sur le médicament'}
          </Title>
          <Preface>
            {'La formation n\'est plus un évènement isolé réservé à une poignée de jours dans l\'année... On se forme en échangeant sur les réseaux sociaux, en lisant une info sur internet, en assistant à une conférence, en discutant avec un confrère...'}
            <br />
            <strong>Et si tous ces moyens étaient réunis en un seul portail ?</strong>
          </Preface>
        </Header>
        <AppPreview>
          <img
            alt={ alt }
            height="475"
            src={ require('assets/images/landing/app2.png') }
            width="550"
          />
          <AppPreviewTextBlock>
            <AppPreviewTextTitle>
              {
                `1.
                Apprendre en s’amusant ?
                La clé d’une bonne mémorisation`
              }
            </AppPreviewTextTitle>
            <p>
              {'En solo ou avec toute l\'équipe, découvrez chaque semaine deux nouveaux quiz sur les médicaments de prescription courante.'}
            </p>
          </AppPreviewTextBlock>
        </AppPreview>
        <AppPreview>
          <AppPreviewTextBlock>
            <AppPreviewTextTitle>
              {
                `2.
                Evaluez votre progression
                grâce à des statistiques avancées`
              }
            </AppPreviewTextTitle>
            <p>
              {'Améliorez votre score en revalidant les Quiz, visualisez votre taux de progression et obtenez vos badges de médoqueurs. Découvrez également toutes les semaines le podium des meilleurs scores !'}
            </p>
          </AppPreviewTextBlock>
          <img
            alt={ alt }
            height="580"
            src={ require('assets/images/landing/app3.png') }
            width="362"
          />
        </AppPreview>
        <AppPreview>
          <img
            alt={ alt }
            height="596"
            src={ require('assets/images/landing/app5.png') }
            width="364"
          />
          <AppPreviewTextBlock>
            <AppPreviewTextTitle>
              {
                `3.
                Révisez ce que vous souhaitez où
                et quand vous le voulez`
              }
            </AppPreviewTextTitle>
            <p>
              {'A la fin de chaque quiz, retrouvez une fiche de synthèse en 10 points sur la classe de médicaments traitée. Votre bibliothèque s\'enrichit avec votre participation :)'}
            </p>
          </AppPreviewTextBlock>

        </AppPreview>
        <Separator />
      </Wrapper>
    </>
  )
}

const infoList = [
  {
    image: require('assets/images/landing/infoItem1.png'),
    alt: 'contenu site',
    title: 'Mise à jour en continu',
    description: 'N’apprenez pas de contenus obsolètes',
  },
  {
    image: require('assets/images/landing/infoItem2.png'),
    alt: 'collaborateurs',
    title: 'Des échanges',
    description: 'entre apprenants sur leurs propres cas',
  },
  {
    image: require('assets/images/landing/infoItem3.png'),
    alt: 'téléphone',
    title: '2 × plus d’engagement',
    description: 'grâce à un format court et ludique',
  },
  {
    image: require('assets/images/landing/infoItem4.png'),
    alt: 'horloge',
    title: '3 min par quiz',
    description: 'à raison de deux nouveaux quiz par semaine',
  },
]

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`

const DailyQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DailyQuestion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding-bottom: 2rem;
  margin: -7rem 0 4rem;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 0 0.8rem 0 rgb(0 0 0 / 22%);

  @media (width <= 1050px) {
    width: 90%;
  }
`

const DailyQuestionContent = styled.p`
  max-width: 40rem;
  margin-bottom: 2rem;
  font-family: 'Brother 1816';
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.375rem;
  color: ${colors.darkBlue};
  text-align: center;

  > strong {
    color: ${colors.lightGreen};
  }
`

const InfoList = styled.ul`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 3rem;

  @media (width <= 1050px) {
    flex-wrap: wrap;
  }
`

const Header = styled.div`
  margin: 0 20%;
  text-align: center;

  @media (width <= 1050px) {
    margin: 0 5%;
  }
`

const Title = styled.h2`
  font-family: 'Brother 1816';
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 900;
  line-height: 3.75rem;
  color: ${colors.midnightBlue};

  @media (width <= 1050px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`

const Preface = styled.p`
  margin: 3rem 0;
  color: ${colors.midnightBlue};
  text-align: center;
`

const AppPreview = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-top: 3rem;

  @media (width <= 975px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  & > img {
    max-width: 100%;
    height: auto;

    @media (width <= 975px) {
      display: flex;
      order: -1;
    }
  }
`

const AppPreviewTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 37rem;
  margin: 0 2rem;
  margin-top: 2rem;
`

const AppPreviewTextTitle = styled.h3`
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: bold;
  line-height: 2.5rem;
  color: ${colors.lightGreen};
  white-space: pre-line;
`

export default PresentationContent
