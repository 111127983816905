import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import { Icon } from 'components/atoms'

const StatisticsBanner: FC = () => {
  return (
    <Wrapper>
      <StatsWrapper>
        <StatsItem>
          <Score>90%</Score>
          <Bold>des participants</Bold>
          ont validé leur formation
        </StatsItem>
        <StatsItem>
          <IconWrapper>
            <Icon name='heart'
              size='3.635rem'
            />
          </IconWrapper>
          <Score>
            9.1
            <Span>/10</Span>
          </Score>
          <Bold>de satisfaction</Bold>
          noté par nos participants *
        </StatsItem>
        <StatsItem>
          <Score>80%</Score>
          <Bold>souhaitent refaire une formation</Bold>
          avec Défimédoc
        </StatsItem>
      </StatsWrapper>
      <Comment>
        *sur la base des réponses reçues à l&apos;enquête de satisfaction menée sur les sessions proposées en 2024
      </Comment>
    </Wrapper>
  )
}
const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (width <= 1339px) {
    flex-direction: column;
    gap: 5rem;
  }
`

const Bold = styled.b`
  font-size: 1.25rem;
`

const Span = styled.span`
  font-weight: normal;
`

const Score = styled.span`
  font-size: 3.125rem;
  font-weight: 700;
`

const StatsItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const Comment = styled.div`
  margin-top: 5.6rem;
  font-size: 0.75rem;

  @media (width <= 1050px) {
    text-align: center;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  top: -3rem;
  right: 0;
  width: 3.635rem;
`

const Wrapper = styled.div`
  position: relative;
  padding: 6.5rem 15.625rem 3.125rem;
  font-family: 'Brother 1816';
  color: ${ colors.white };
  letter-spacing: 0.05rem;
  background-image: url(${require('assets/images/dpc/statistics.png')});
  background-repeat: no-repeat;
  background-size: cover;

  @media (width <= 1339px) {
    padding: 3.125rem 1.313rem;
    background: linear-gradient(126.45deg, #5672c7 -30.82%, #726aff 94.06%);
  }
`

export default StatisticsBanner
