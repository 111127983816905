import { useState, useMemo, useCallback } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { VERIFY_EMAIL, RESEND_ACTIVATION_EMAIL } from 'graphql/mutations/user'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LOGIN_PATH, ACTIVATE_EMAIL_PATH } from 'components/Router'
import Spinner from 'components/atoms/Spinner'
import { Button } from 'components/atoms'
import styled from 'styled-components'
import { colors } from 'theme'
import AuthLayout from 'components/layouts/AuthLayout'

const landingMessage = 'Félicitations, vous êtes à un clic de valider votre compte ! Veuillez activer votre compte en cliquant sur ce bouton :'

enum ErrorStatus {
  ERROR_MISSING_URL = 'ERROR_MISSING_URL',
  ERROR_MISSING_EMAIL = 'ERROR_MISSING_EMAIL',
  ERROR_LINK_EXPIRED = 'ERROR_LINK_EXPIRED',
  ERROR_LINK_INVALID = 'ERROR_LINK_INVALID',
  ERROR_GENERIC = 'ERROR_GENERIC'
}

enum SuccessStatus {
  EMAIL_VERIFIED_SUCCESS = 'EMAIL_VERIFIED_SUCCESS',
}

type Status = ErrorStatus | SuccessStatus

const errorMessages: Record<ErrorStatus, string> = {
  [ErrorStatus.ERROR_LINK_EXPIRED]: 'Le lien a expiré, veuillez en demander un nouveau en cliquant sur le bouton ci-dessous :',
  [ErrorStatus.ERROR_LINK_INVALID]: 'Le lien n\'est pas valide, veuillez en demander un nouveau en cliquant sur le bouton ci-dessous :',
  [ErrorStatus.ERROR_MISSING_EMAIL]: 'L\'email est nécessaire pour la validation du compte.',
  [ErrorStatus.ERROR_MISSING_URL]: `Le lien d'activation est invalide, merci de contacter ${process.env.REACT_APP_CONTACT_ADDRESS}.`,
  [ErrorStatus.ERROR_GENERIC]: `Erreur inconnue, merci de contacter ${process.env.REACT_APP_CONTACT_ADDRESS}`,
}

const successMessage: Record<SuccessStatus, string> = {
  EMAIL_VERIFIED_SUCCESS: 'Email validé avec succès ! Vous allez être redirigé vers la page de connexion.',
}

enum ButtonLabels {
  ACTIVATE_ACCOUNT = 'Activer mon compte',
  RESEND_EMAIL = 'Renvoyer un email de validation',
}

const EmailActivation = () => {
  const query = new URLSearchParams(window.location.search)
  const hash = query.get('_hash')
  const email = query.get('email')
  const expires = query.get('expires')
  const navigate = useNavigate()
  const [status, setStatus] = useState<Status|null>(null)
  const [activateEmail, { loading: activeEmailLoading }] = useMutation(VERIFY_EMAIL)
  const [resendActivationEmail, { loading: resendActivationEmailLoading }] = useMutation(RESEND_ACTIVATION_EMAIL)

  const isValidUrl = useMemo(() => {
    return hash && email && expires && window.location.pathname.startsWith(ACTIVATE_EMAIL_PATH)
  }, [hash, email, expires])

  const buttonIsShown = useMemo(() => {
    return isValidUrl && !activeEmailLoading && status !== ErrorStatus.ERROR_GENERIC
  }, [status, activeEmailLoading, isValidUrl])

  const buttonLabel = useMemo(() => {
    if (status === ErrorStatus.ERROR_LINK_EXPIRED || status === ErrorStatus.ERROR_LINK_INVALID) {
      return ButtonLabels.RESEND_EMAIL
    }

    return ButtonLabels.ACTIVATE_ACCOUNT
  }, [status])

  const message = useMemo(() => {
    if (activeEmailLoading) {
      return 'Validation de votre email en cours...'
    }

    if (status === SuccessStatus.EMAIL_VERIFIED_SUCCESS) {
      return successMessage[status]
    }

    if (status) {
      return errorMessages[status]
    }

    return null
  }, [status, activeEmailLoading])

  const verifyEmail = useCallback(async () => {
    if (!isValidUrl) {
      setStatus(ErrorStatus.ERROR_MISSING_URL)

      return
    }

    if (!email) {
      setStatus(ErrorStatus.ERROR_MISSING_EMAIL)

      return
    }

    const fullUrl = window.location.href

    try {
      await activateEmail({ variables: { url: fullUrl, email: email }})

      setStatus(SuccessStatus.EMAIL_VERIFIED_SUCCESS)
      navigate(`${LOGIN_PATH}?email=${encodeURIComponent(email)}`)
    } catch (error) {
      const apolloError = error as ApolloError
      const errorStatusCode = apolloError.graphQLErrors && apolloError.graphQLErrors[0] ? apolloError.graphQLErrors[0].message : ErrorStatus.ERROR_GENERIC

      if (Object.keys(ErrorStatus).includes(errorStatusCode)) {
        setStatus(errorStatusCode as ErrorStatus)

        return
      }

      setStatus(ErrorStatus.ERROR_GENERIC)
    }
  }, [email, isValidUrl, activateEmail, navigate])

  const resendEmail = useCallback(async () => {
    try {
      await resendActivationEmail({ variables: { email }})

      toast.success('Un email de réactivation a été renvoyé', {
        position: 'top-center',
      })
    } catch (_) {
      toast.error('Oups ! Un problème est survenu lors de l\'envoi de l\'email', {
        position: 'top-center',
      })
      setStatus(ErrorStatus.ERROR_GENERIC)
    }
  }, [email, resendActivationEmail])

  const handleButtonClick = useCallback(() => {
    if (status === ErrorStatus.ERROR_LINK_EXPIRED || status === ErrorStatus.ERROR_LINK_INVALID) {
      return resendEmail()
    }

    return verifyEmail()
  }, [status, resendEmail, verifyEmail])

  return (
    <AuthLayout>
      <ContentWrapper>
        {
          !activeEmailLoading && !message && <Message>{ landingMessage }</Message>
        }
        {
          activeEmailLoading && <Spinner />
        }
        {
          message && <Message>{message}</Message>
        }
        {
          buttonIsShown && (
            <Button
              disabled={ resendActivationEmailLoading || activeEmailLoading }
              variant="primary"
              onClick={ handleButtonClick }
            >
              { buttonLabel }
            </Button>
          )
        }
      </ContentWrapper>
    </AuthLayout>
  )
}

const ContentWrapper = styled.div`
  flex:1;
`

const Message = styled.p`
  margin-top: 20px;
  font-size: 1.2rem;
  color: ${colors.gray};
`

export default EmailActivation
