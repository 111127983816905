import React, { useContext } from 'react'
import { rgba } from 'polished'
import styled from 'styled-components'
import { Icon, Button } from 'components/atoms'
import { ModalContext } from 'context/Modal'
import { colors, layout } from 'theme'
import Giphar from './../assets/images/consortium/giphar.png'
import Giropharm from './../assets/images/consortium/giropharm.png'
import Fspf from './../assets/images/consortium/fspf.png'
import Uspo from './../assets/images/consortium/uspo.png'
import Ubipharm from './../assets/images/consortium/ubipharm.png'
import VingtQuatreSept from './../assets/images/consortium/24-7.png'
import Totum6 from './../assets/images/consortium/totum6.png'
import PharmUpp from './../assets/images/consortium/pharmupp.png'
import Defimedoc from './../assets/images/consortium/defimedoc.png'
import PharmaConsulting from './../assets/images/consortium/pharmaconsulting.png'
import CapUnipharm from './../assets/images/consortium/capunipharm.png'
import UrpsPharmacien from './../assets/images/consortium/urpspharmacien.png'
import Dephie from './../assets/images/consortium/dephie.png'
import Ceido from './../assets/images/consortium/ceido.png'
import Act from './../assets/images/consortium/act.png'
import Oxygenpharma from './../assets/images/consortium/oxygenpharma.png'
import Pharma2m from './../assets/images/consortium/pharma2m.png'
import _37dms from './../assets/images/consortium/37dms.png'
import teampharma1 from './../assets/images/consortium/teampharma1.png'
import mfo from './../assets/images/consortium/mfo.png'
import synaps from './../assets/images/consortium/synaps.png'
import { isPharmUppUltra, isSoloDefimedoc, isUbipharm, isSponsoring } from 'utils/origin'

const Modal = () => {
  const { state, actions } = useContext(ModalContext)

  const {
    isOpen,
    consortium: {
      name,
      abonnementType,
      trialPeriod,
      oldPeriod,
      newPeriod,
      periodDescription,
      discounts,
      descriptions,
    },
    validate,
  } = state

  const handleModalClose = actions.closeModal

  if (!isOpen) {
    return null
  }

  return (
    <Wrapper
      onClick={ handleModalClose }
    >
      <Overlay>
        <ModalWrapper>
          <StyledIcon
            fill={ colors.white }
            name="close"
            size="1.7rem"
            onClick={ handleModalClose }
          />
          <ModalContent>
            {/*eslint-disable */}
            <Title>
              <TitleText>
                {
                  isPharmUppUltra()
                  ?
                    'Offre spéciale ULTRA'
                    : 'Offre spéciale'
                }
              </TitleText>
              <TitleLogo>
                { name === 'Giphar' && <Logo alt={ name } src={ Giphar } /> }
                { name === 'Giropharm' && <Logo alt={ name } src={ Giropharm } /> }
                { name === 'FSPF' && <Logo alt={ name } src={ Fspf } /> }
                { name === 'USPO' && <Logo alt={ name } src={ Uspo } /> }
                { name === 'Lab\'Officine' && <Logo alt={ name } src={ Labofficine } /> }
                { name === 'Ubipharm' && <Logo alt={ name } src={ Ubipharm } /> }
                { name === 'Teams' && <span>Teams</span> }
                { name === '24-7 Services' && <Logo alt={ name } src={ VingtQuatreSept } /> }
                { name === 'Club du comptoir' && <Logo alt={ name } src={ ClubDuComptoir } /> }
                { name === 'Totum' && <Logo alt={ name } src={ Totum6 } /> }
                { name === 'Pharm-UPP Essentiel' && <Logo alt={ name } src={ PharmUpp } /> }
                { name === 'Pharm-UPP ULTRA' && <Logo alt={ name } src={ PharmUpp } /> }
                { name === 'Solo Défimedoc' && <Logo alt={ name } src={ Defimedoc } /> }
                { name === 'Pharma Consulting' && <Logo alt={ name } src={ PharmaConsulting } /> }
                { name === 'Cap Unipharm' && <Logo alt={ name } src={ CapUnipharm } /> }
                { name === 'URPS Pharmaciens Ile-de-France' && <Logo alt={ name } src={ UrpsPharmacien } /> }
                { name === 'Déphie' && <Logo alt={ name } src={ Dephie } /> }
                { name === 'Ceido' && <Logo alt={ name } src={ Ceido } /> }
                { name === 'Act Pharmacie' && <Logo alt={ name } src={ Act } /> }
                { name === 'Oxygen\'Pharma' && <Logo alt={ name } src={ Oxygenpharma } /> }
                { name === 'Pharma2M' && <Logo alt={ name } src={ Pharma2m } /> }
                { name === '37DMS' && <Logo alt={ name } src={ _37dms } /> }
                { name === 'Team Pharma' && <Logo alt={ name } src={ teampharma1 } /> }
                { name === 'Ma Formation Officinale' && <Logo alt={ name } src={ mfo } width={250} /> }
                { name === 'Synaps' && <Logo alt={ name } src={ synaps } /> }
              </TitleLogo>
            </Title>
            { !isUbipharm() && !isPharmUppUltra()
              ?
              <>
                <AbonnementType>
                  Bénéficiez des conditions spéciales{!isSponsoring() && '*'} { name } sur votre
                  <strong> abonnement { abonnementType }</strong>
                </AbonnementType>
                {
                  !isSoloDefimedoc()
                    ?
                    <>
                      <TrialPeriod>
                        { trialPeriod }
                      </TrialPeriod>
                    </>
                    :
                    <>
                      <TrialPeriodWrapper>
                        <TrialPeriod>
                          <StrikedText>{ oldPeriod }</StrikedText> { newPeriod }
                        </TrialPeriod>
                        { periodDescription }
                      </TrialPeriodWrapper>
                    </>
                }
                {
                  discounts.map((discount, index) => {
                    if(discount.type === 'fix') {
                      return (
                        <Discount key={ index }>
                          puis <Price>{ discount.newPrice }</Price> au lieu de <ExpiredPrice>{ discount.oldPrice }</ExpiredPrice>
                        </Discount>
                      )
                    } else if(discount.type === 'solo') {
                      return (
                        <Discount key={ index }>
                          puis <Price>{ discount.price }</Price>
                        </Discount>
                      )
                    } else {
                      return (
                        <Discount key={ index }>
                          <strong>{ discount.amount } </strong>
                          { discount.description }
                          { discount.specialMarketing && <strong> { discount.specialMarketing }</strong> }
                        </Discount>
                      )
                    }
                  })
                }
                {
                  !isSponsoring() && <Until>*Selon partenariat en cours de validité</Until>
                }
              </>
              : <>
                { descriptions.map(description =>
                  <Message key={ description.message }>
                    { description.message }
                  </Message>)
                }
              </>
            }
            <ActionsWrapper>
              <ActionButton
                variant="primary"
                onClick={ validate.button.handleClick }
              >
                { validate.button.text }
              </ActionButton>
              {!validate.isLogged && (
                <SignInPrompt>
                  {validate.signInPrompt.question}{' '}
                  <SignInLink onClick={validate.signInPrompt.handleClick}>
                    {validate.signInPrompt.text}
                  </SignInLink>
                </SignInPrompt>
              )}
            </ActionsWrapper>
          </ModalContent>
        </ModalWrapper>
      </Overlay>
    </Wrapper>
  )
}

const toggleDuration = 200

const Wrapper = styled.div`
  position: fixed;
  z-index: ${layout.modalZIndex};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Overlay = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${rgba(colors.midnightBlue, 0.4)};
  transition: background-color ${toggleDuration}ms ease-in-out;
  transition-delay: ${toggleDuration / 2}ms;
`

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: calc(100% - 308px);
  min-height: 15rem;
  max-height: calc(100% - 154px);

  @media (width <= 820px) {
    width: min-content;
    max-width: none;
  }
`

const ModalContent = styled.div`
  overflow: auto;
  font-family: 'Brother 1816';
  color: ${colors.darkBlue};
  text-align: center;
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 43%);

  @media (width <= 820px) {
    width: min-content;
    max-width: none;
  }
`

const TitleLogo = styled.div`
  text-align: center;
`

const TitleText = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`

const Title = styled.div`
  padding: 2rem 7rem 0;
  font-size: 2.5rem;
  font-weight: 900;
  color: ${colors.darkBlue};
  text-align: center;
  background-position: right;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media (width <= 820px) {
    padding-inline: 5rem;
    font-size: 1.5rem;
    line-height: 4rem;
  }
`

const TrialPeriodWrapper = styled.div`
  padding: 0;
  margin: 0;
  font-family: 'Brother 1816';
  font-size: 2.3rem;
  font-weight: 900;
  line-height: 3rem;
  color: ${colors.lightGreen};

  @media (width <= 1090px) {
    font-size: 2rem;
  }

  @media (width <= 820px) {
    font-size: 1.2rem;

    strong {
      font-size: 1.3rem;
    }
  }
`

const TrialPeriod = styled.p`
  padding: 0;
  margin: 0;
  font-family: 'Brother 1816';
  font-size: 2.3rem;
  font-weight: 900;
  line-height: 3rem;
  color: ${colors.lightGreen};

  @media (width <= 1090px) {
    font-size: 2rem;
  }

  @media (width <= 820px) {
    font-size: 1.2rem;

    strong {
      font-size: 1.3rem;
    }
  }
`

const AbonnementType = styled.p`
  padding: 0 4rem;
  margin: 1rem 0;
  font-family: 'Brother 1816';
  font-size: 1.2rem;

  @media (width <= 820px) {
    padding: 0 1rem;
    font-size: 1rem;
  }
`

const Message = styled.p`
  max-width: 50rem;
  padding: 2rem 4rem 0;
  margin: 0;
  font-family: 'Brother 1816';
  line-height: 3rem;

  @media (width <= 820px) {
    padding: 0 1rem;
    font-size: 1rem;
  }

  &:nth-child(5) {
    padding-bottom: 4rem;
  }
`

const Discount = styled.p`
  padding: 0;
  margin: 0;
  font-family: 'Brother 1816';
  font-size: 2rem;
  line-height: 3rem;

  strong {
    font-size: 2.3rem;
    font-weight: 900;
    color: ${colors.lightGreen};
  }

  @media (width <= 1090px) {
    font-size: 2rem;
  }

  @media (width <= 820px) {
    font-size: 1.2rem;

    strong {
      font-size: 1.3rem;
    }
  }
`

const Until = styled.p`
  margin-top: 2rem 0;
  font-family: 'Brother 1816';
  font-size: 0.8rem;
  font-weight: 500;

  @media (width <= 820px) {
    margin: 0;
  }
`

const StyledIcon = styled(Icon)`
  && {
    position: absolute;
    top: -45px;
    right: -120px;
    padding: 1.8125rem;
    cursor: pointer;
    background-color: ${colors.lightGreen};
    border-radius: 100%;

    @media (width <= 1090px) {
      top: 0;
      right: 0;
      padding: 1rem;
      margin: 0.5rem;
    }

    @media (width <= 820px) {
      padding: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ActionButton = styled(Button)`
  && {
    display: inline-block;
    width: 200px;
    height: 30px;
    margin: 2rem auto;
    text-align: center;
    background-image: linear-gradient(to right, ${colors.seaGreen}, ${colors.lightGreen});
  }
`

const SignInPrompt = styled.p`
  font-size: 1.30rem;
  color: ${colors.darkBlue};
`

const SignInLink = styled.span`
  font-weight: bold;
  color: ${colors.seaGreen};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

const Price = styled.span`
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: bold;
`

const ExpiredPrice = styled.span`
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: bold;
  text-decoration: line-through;
`

const StrikedText = styled.span`
  color: ${colors.black};
  text-decoration: line-through;
`

const Logo = styled.img`
  max-height: 100px;
`

export default Modal
