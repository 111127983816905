import React from 'react'
import Markdown from 'components/atoms/Markdown'
import styled from 'styled-components'
import PolicyLayout from 'components/layouts/PolicyLayout'

const TermsOfUse = () =>
  <PolicyLayout>
    <Title>Conditions générales d’utilisation et de vente</Title>
    <Markdown>
      {
        `
Attention : si vous n’acceptez pas ces conditions générales, vous ne pouvez pas utiliser nos services. Pour toute question vous pouvez nous contacter en nous envoyant un email à l’adresse suivante : ${process.env.REACT_APP_CONTACT_ADDRESS}.

Les présentes Conditions Générales d’Utilisation dans leur version datée du 01/08/2019 (ci-après dénommée « CGU/CGV ») sont un contrat qui est formé entre :
« Nous », Défimédoc, Société par Actions Simplifiée, enregistrée au R.C.S. de Nanterre sous le n°852 559 822, dont le siège social est situé 15 T Boulevard du Maréchal Foch, 49100 Angers, représentée par Mme Florence BONTEMPS en sa qualité de Présidente, duement habilitée aux fins des présentes.
et
« Vous » ou « Utilisateur », toute personne physique utilisateur de nos services dans le cadre de son activité professionnelle de santé au titre d’un Abonnement Principal ou d’un Compte Rattaché (tels que définis à l’article 14).

Nos CGU/CGV ont pour objet de fixer les conditions et les modalités dans lesquelles vous pouvez utiliser nos services définis ci-après.
Dans le cas où vous accédez à d’autres services que nous éditons et qui ne sont pas décrits ci-dessous, vous devrez lire et accepter les conditions générales propres à ces autres services préalablement à leur utilisation.

_**1. Définitions**_

- 1.1 Application(s) : désigne toute application mobile incluant l’application Défimédoc ainsi que tous ses Contenus permettant l’accès à nos Services, quel que soit son format, sa taille, son extension ou le matériel, la plateforme ou le système d’exploitation sur lequel elle s’exécute et faisant l’objet de la Section « Utilisation de l’Application ».

- 1.2 Contenu(s) : désigne l’ensemble des éléments qui composent nos Services, qui sont mis à votre disposition, délivrés, communiqués, fournis ou affichés dans le cadre, au moyen ou pour les besoins de la fourniture de nos Services. Ils comprennent notamment tous les textes, images, dessins, graphiques, photographies, sons, musiques, vidéos, œuvres multimédias, fichiers, données, bases de données, logiciels, codes informatiques, marques, noms, logos et plus généralement tous les contenus, les éléments ou les informations qui sont susceptibles d’être protégés par un droit de propriété matérielle ou intellectuelle. Sont notamment considérés comme des Contenus : les Quiz, les Fiches l’Essentiel, les fils de discussion.

- 1.3 Services : désigne les services couverts par les présentes CGU/CGV c’est-à-dire, l’accès aux Contenus, à l’Application et les services tels que décris ci-après :

  Détail des services

  - Défimédoc est une application mobile destinée aux professionnels de santé, dont le but est d’entretenir et mettre à jour ses connaissances sur le médicament.

  - Défimédoc propose des Quiz formatifs sur des classes de médicaments ou des molécules thérapeutiques, des échanges entre professionnels de santé sur les thématiques des Quiz, et l’accès à des fiches de synthèse sur les molécules ou classes de médicaments de même thématique que les Quiz.

  - L’Application se divise en quatre rubriques.

    - 1.3.1 Rubrique Quiz

      La rubrique Quiz comporte des questions/réponses sur un médicament ou une classe de médicaments, du matériel médical, une pathologie ou tout autre domaine relatif à la santé. Un nouveau quiz est proposé deux fois par semaine.

    - 1.3.2 Rubrique Fiches L’Essentiel

      La rubrique « Fiches L’Essentiel » permet d’accéder à un ensemble de fiches de synthèses sur les médicaments et les produits de santé. A chaque thématique de Quiz correspond une Fiche L’Essentiel. La fiche est consultable une fois que le quiz correspondant a été « finalisé ». Les fiches comportent les sources bibliographiques.

    - 1.3.3 Rubrique Scores

      La rubrique Scores donne accès à différents éléments visuels représentant les statistiques concernant l’Apprenant (score cumulé, taux d’amélioration, taux de participation…) et son positionnement par rapport aux autres apprenants (histogramme des scores moyens et positionnement…).

    - 1.3.4 Rubrique Discussions

      La rubrique « Discussions » correspond à des échanges entre Apprenants, autour des thématiques abordées dans les Quiz. L’accès aux Discussions d’une thématique n’est ouvert qu’aux Apprenants ayant finalisé le Quiz correspondant (validé ou non).

- 1.4 Terminal : désigne tout équipement, machine, matériel ou objet avec lequel vous accédez à nos Services (ordinateur, smartphone, objet connecté, tablette, etc.).

_**2. Acceptation des CGU/CGV**_

- 2.1 Toute utilisation de nos Services est soumise à la condition absolue que vous acceptiez sans aucune réserve toutes les règles définies dans les présentes CGU/CGV. L’acceptation des CGU/CGV est matérialisée concrètement par le fait de cocher une case prévue à cet effet.

- 2.2 Si vous n’acceptez pas l’une de ces règles, vous n’avez alors aucun droit d’utiliser nos Services et ne devez donc pas les utiliser.- 2.3 Pour la bonne gestion de nos Services (notamment pour l’ajout et la suppression de fonctionnalités), nous pouvons à tout moment modifier les CGU/CGV. Chaque nouvelle version remplace immédiatement la version précédente. Chaque nouvelle version de nos CGU/CGV sera mise en ligne sur notre site internet à l’adresse suivante : www.defimedoc.fr/cgu-cgv ou via l’Application.

- 2.4 Vous êtes donc invités à vous référer aux CGU/CGV applicables au moment de votre utilisation des Services.

- 2.5  Si vous n’acceptez pas la nouvelle version des CGU/CGV, vous n’avez alors aucun droit d’utiliser nos Services et devez donc arrêter de les utiliser.

_**3. Accès et disponibilités de nos Services**_

- 3.1 Nous fournissons la possibilité d’accéder aux fonctionnalités de nos Services si vous bénéficiez par ailleurs déjà d’un accès à internet. Les couts afférents à l’existence, au maintien et à la qualité de cet accès, qu’il s’agisse de frais d’achat et d’entretien de tout matériel nécessaire (incluant le Terminal), des frais d’accès à internet, des frais d’abonnement ou de communications électroniques fixes ou mobiles sont exclusivement à votre charge. Vous êtes seul responsable du bon fonctionnement de ces équipements et accès.

- 3.2 Nous faisons nos meilleurs efforts afin de que vous puissiez accéder à nos Services sans interruption et dans les meilleures conditions possibles. Cependant, nous ne sommes pas tenus à ce que nos Services soient disponibles de façon continue. Vous acceptez ainsi expressément que pour toute raisons et notamment toute opération de maintenance ou d’administration nous puissions suspendre l’accès à tout ou partie des Services et/ou à certaines fonctionnalités et/ou à certains Contenus.

- 3.3 Nous sommes également libres de mettre un terme temporaire ou définitif à nos Services et ceci sans conditions ni délais préalables. Vous n’avez donc aucun droit au maintien de l’existence de nos Services ni à aucune indemnisation dans le cas où nous déciderions de les suspendre ou d’y mettre un terme définitif. Dans le cas d’un accès payant aux Services votre obligation de payer sera automatiquement résiliée à la date d’arrêt définitif des Services ou de la résiliation définitive de votre accès au Service.

- 3.4 Par ailleurs, si vous ne respectez pas les présentes CGU/CGV, nous nous réservons la possibilité de suspendre, limiter ou mettre un terme définitif à votre accès aux Services.

- 3.5 Vous ne bénéficiez d’aucun droit automatique ou absolu d’accès à nos Services ou de maintien d’accès. Tout arrêt temporaire ou définitif de l’accès à nos Services, quel qu’en soit le motif ne donne lieu à aucune indemnité, ni compensation.

_**4. Création, gestion et suppression d’un compte utilisateur**_

- 4.1 Généralités

  - 4.1.1 Pour accéder aux Services, il est nécessaire de créer un compte utilisateur, qui est un espace dédié de nos Services auquel vous pouvez accéder pour gérer les informations vous concernant. Ce compte utilisateur reste notre propriété, vous ne disposez que d’un droit d’accès limité aux informations et données contenues dans ce compte utilisateur pour permettre votre utilisation de nos Services.

  - 4.1.2 Vous ne disposez pas automatiquement du droit de créer un compte personnel. Nous nous réservons le droit de refuser votre inscription ou d’y mettre un terme ponctuel ou définitif pour tout motif légitime, notamment le non-respect des présentes CGU/CGV.

  - 4.1.3 Pour créer un compte utilisateur, vous devez :

    - être une personne physique majeure (selon les lois de votre pays de résidence) ;

    - disposer de votre pleine capacité juridique de contracter avec nous ;

    - être un professionnel de santé de l’une de ces catégories : médecin, pharmacien, préparateur en pharmacie, sage-femme justifiant de cette qualité par la fourniture d’un justificatif à jour (n° RPPS valide, copie d’un diplôme reconnu par l’Etat, copie de la carte d’inscription à l’ordre, copie carte d’étudiant...).

  - 4.1.4 La création et la gestion de votre compte utilisateur doit obligatoirement respecter les principes énoncés dans la Section « Règles de bonne conduite » ci-dessous.

  - 4.1.5 Votre compte utilisateur est strictement personnel et unique, vous ne pouvez créer qu’un seul compte utilisateur. A l’exception de nos droits d’administration, il ne peut être créé, géré et supprimé que par vous-même, il ne peut contenir que des informations vous concernant, vous êtes seul autorisé à y accéder et ne devez jamais communiquer vos identifiants à un tiers. Vous êtes responsable d’assurer la confidentialité de vos identifiants.

  - 4.1.6 Vous vous engagez formellement à renseigner les informations nécessaires à la création de votre compte utilisateur de façon honnête, sincère et à maintenir les informations à jour en cas de modification. Vous vous engagez également à avoir le droit de nous communiquer ces informations et à ne pas porter atteinte à un quelconque droit de tiers, notamment au droit à la vie privée et aux droits de propriété intellectuelle.

  - 4.1.7 Nous ne sommes en aucun cas responsables de la qualité, l’exactitude, la nature ou le contenu des informations que vous nous communiquez.

- 4.2 Création

  - 4.2.1 La création d’un compte utilisateur requiert obligatoirement les informations authentiques suivantes vous concernant : nom, prénom, profession, adresse mail, mot de passe. D’autres informations (adresse personnelle, adresse de la pharmacie, téléphone…) sont facultatives et sont demandées à des fins statistiques ou afin de vous proposer de nouvelles offres créées par Défimédoc. Sauf mention explicite, ces informations ne sont pas visibles des autres utilisateurs.

  - 4.2.2 Nous nous réservons le droit, notamment au moment de la création de votre compte utilisateur (en particulier par l’envoi d’un SMS ou email de confirmation) mais également par la suite, de vous demander de confirmer ces informations dans le but d’assurer le meilleur niveau de confiance possible de chaque utilisateur à l’égard du Service.

- 4.3 Gestion

  - 4.3.1 La gestion de votre compte utilisateur requiert que les informations suivantes ne puissent pas être modifiées par vous-même : nom, prénom, adresse mail. En cas de besoin de modification de ces éléments, vous pouvez nous joindre à l’adresse ${process.env.REACT_APP_CONTACT_ADDRESS}.

  - 4.3.2 Dans le but d’assurer la meilleure personnalisation du Service, vous serez amené à renseigner des informations permettant la mise en relation avec d’autres utilisateurs tels que pseudo, fonction, fil de discussion suivis.

- 4.4 Suppression

  - 4.4.1 Votre compte utilisateur sera automatiquement supprimé si vous n’utilisez pas les Services pendant une période continue de trois ans.

  - 4.4.2 Vous pouvez supprimer votre compte utilisateur en adressant une demande en ce sens à l’adresse ${process.env.REACT_APP_CONTACT_ADDRESS}. Cette action est irréversible et supprime toutes les données associées à votre compte. Les messages que vous avez postés sont conservés mais rendu anonymes (suppression du pseudo associé).

_**5. Données collectées**_

- 5.1 Données statistiques

  - 5.1.1 Nous vous informons que nous pouvons collecter des données anonymes visant à l’analyse statistique de l’utilisation de nos Services. Ces données ne contiennent pas de données personnelles et ne présentent aucun impact ou menace sur votre vie privée. Défimédoc en est seul propriétaire.

- 5.2 Données personnelles

  - 5.2.1 La collecte et le traitement de données personnelles vous concernant sont réalisés dans le respect des dispositions de la loi n° 78-17 du 6 janvier 1978 modifiée, relative à l'informatique, aux fichiers et aux libertés. A ce titre vous pouvez exercer votre droit d'accès, de modification et de suppression des données en contactant : ${process.env.REACT_APP_CONTACT_ADDRESS}.

  - 5.2.2 Nous collectons et utilisons ces données personnelles conformément à notre charte de confidentialité disponible à l’adresse suivante : www.defimedoc.fr/charteconfidentialite ou via l’Application.

  - 5.2.3 L’acceptation du traitement de vos données personnelles par nos soins ou par nos prestataires est une condition essentielle à l’utilisation de nos Services.

- 5.3 Cookies et Traceurs

  - 5.3.1 Par ailleurs, nous utilisons également à des fins de suivi de l’utilisation de nos Services, des cookies utilisateurs, issus notamment des outils suivants :

    - Cookie de connexion (l'adresse IP, dates et heures de connexion...)

    - Cookie de performance (origine des connexions).

  - 5.3.2 Ces outils analysent les flux de connexion et de données qui transitent via nos Services et nous fournissent des informations d’utilisation nous permettant de comprendre votre utilisation de nos Services et nous donnent des pistes et des idées pour les améliorer. Ils permettent notamment de collecter, pour le site www.defimedoc.fr, les key performance indicators, le taux de rebond et de conversion, les sources et lieux de connexion, le support de connexion (web, mobile, IOS, Android), etc.

  - 5.3.3 Vous pouvez modifier les conditions de gestion, de stockage et de destruction des cookies à partir des pages de configuration de votre navigateur. Nous attirons votre attention sur le fait que les cookies sont parfois nécessaires au fonctionnement de certaines fonctionnalités de notre site web (ainsi qu’à celles de certains sites tiers) ou à la fourniture des Services. Soyez vigilants lorsque vous modifiez le comportement de votre navigateur.

_**6. Propriété intellectuelle**_

- 6.1 Les Services, les Applications et Contenus (tels que définis au début des présentes CGU/CGV) sont notre propriété exclusive (ou notre copropriété avec des tiers) et sont protégés par les lois nationales, européennes et internationales sur le droit d'auteur (ou copyright) et plus généralement sur la propriété intellectuelle (marques, dessins et modèles, brevets, bases de données, logiciels et tous autres droits similaires).

- 6.2 Toute utilisation quelle qu’elle soit en dehors de ce qui est expressément autorisé par les présentes CGU/CGV et notamment toute représentation, reproduction, copie, adaptation, modification, exploitation, vente, location, totale ou partielle de nos Services, Applications et Contenus (notamment les Quiz, les Fiches l’Essentiel, les fils de discussion) est strictement interdite sans notre autorisation écrite préalable. Toute utilisation ou acte constituant une violation des présentes CGU/CGV peut justifier la limitation, la suspension ou l’arrêt définitif de votre accès aux Services et pourra, le cas échéant, vous exposer à des poursuites judiciaires.

- 6.3 Pour clarification, le fait que nous vous autorisions à accéder aux Contenus via nos Services (de façon gratuite ou payante) ne vous donne en aucun cas le droit de les exploiter autrement qu’en votre qualité d’utilisation ou client final et conformément aux présentes CGU/CGV.

- 6.4 Par ailleurs, il vous est également interdit d’extraire ou tenter d’extraire un Contenu depuis sa source quelle que soit cette source (site web, application, publication, etc.), ainsi qu’accéder ou tenter d’accéder au code source de tout Service, Application ou Contenu numérique.

- 6.5 Ces interdictions sont valables quelle que soit l’utilisation envisagée, même si cette utilisation est à but non lucratif. Bien entendu, vous ne pouvez faire aucun usage commercial ou contre une contrepartie (quelle qu’elle soit) de nos Services, Applications et Contenus.

- 6.6 Vous vous interdisez de revendiquer un quelconque droit de propriété matérielle ou intellectuelle sur nos Services, Applications et Contenus. Vous avez l’interdiction notamment de déposer des marques, logos ou autre ou de faire enregistrer des droits d’auteurs sur ces éléments ou sur tout élément identique ou similaire.

- 6.7 Vous restez entièrement propriétaire de toute production réalisée par vous dans le cadre de votre utilisation de nos Services. Vous acceptez de nous concéder à titre gratuit, pour la durée d’existence des droits en cause et pour la satisfaction de tout besoin en lien avec le fonctionnement, la fourniture, l’utilisation et la commercialisation de nos Services, une licence d’utilisation sur vos productions. Vous acceptez que nous puissions exercer ce droit par nous-mêmes ou par l’intermédiaire de tout tiers. Cette licence d’utilisation comprend notamment les droits de reproduction et représentation. En particulier, vous acceptez que vous échanges dans le cadre des discussions via nos Services, puissent être utilisés pour créer de nouveaux Contenus.

_**7. Utilisation de l’Application**_

- 7.1 Sous réserve de l’acceptation préalable et du respect des CGU/CGV, nous vous concédons une licence simple et personnelle d’utilisation limitée, non-exclusive, non transférable et révocable à tout moment sur l’Application. Vous pouvez télécharger notre application à partir des magasins d’applications d’iOS ou d’Android. Cette licence est exclusivement destinée à vous permettre d’utiliser les Services que nous vous fournissons.

- 7.2 Vous vous engagez expressément à ne faire directement ou indirectement aucune utilisation de l’Application qui pourrait compromettre le fonctionnement, la sécurité ou l’accès aux Services par vous-même ou par les autres utilisateurs.

- 7.3 Vous ne pouvez utiliser l’Application que conformément aux présent CGU/CGV, toute autre utilisation est strictement interdite. Notamment, vous n’avez pas le droit de réaliser les actions suivantes sur l’Application ou ses Contenus, que ce soit par vous-même ou par l’intermédiaire de tout tiers et ce à quelque titre que ce soit :

  - 7.3.1 accéder ou tenter d’accéder au code source, procéder à une modification ou altération quelconque du Contenu ou de l’Application notamment par reverse engineering, décompilation, désassemblage ou autres ;

  - 7.3.2 revendre, exploiter ou mettre à la disposition de tout tiers gratuitement ou contre une contrepartie quelconque, tout élément du Contenu ou de l’Application ;

  - 7.3.3 utiliser pour le développement de toute œuvre dérivée ou produit ou service lié au Contenu ou à l’Application en ce compris les programmes informatiques, les sites web et applications mobiles ;

  - 7.3.4 concevoir, développer, financer ou participer d’une façon quelconque au développement d’outils ayant pour objet ou pour fonctionnalité d’altérer l’Application ou les Contenus ou les utiliser d’une façon non conforme aux CGU/CGV.

- 7.4 Toute utilisation ou acte contraire à ce qui précède constitue une violation des présentes CGU/CGV et justifie la limitation, la suspension ou l’arrêt définitif de votre accès aux Services et pourra, le cas échéant, vous exposer à des poursuites judiciaires.

_**8. Règles de bonne conduite**_

- 8.1 Règles générales

  - 8.1.1 Vous vous engagez notamment, tant à l’écrit qu’à l’oral et en toute occasion de votre utilisation de nos Services à ne pas tenir à notre égard ainsi qu’à l’égard des autres utilisateurs ou des tiers de propos :

    - 8.1.1.1 contraires aux lois et règlements français, européens ou de toute autre juridiction qui vous sont applicables ou qui seraient applicables aux Services ;

    - 8.1.1.2 insultants, menaçants, choquants, contraires aux bonnes mœurs, à la décence ou à la courtoisie ;

    - 8.1.1.4 qui serait de nature à être considérés comme inappropriés eu égard à leur forme, leur contenu, leur insistance, leur teneur ou leur vocabulaire ;

    - 8.1.1.5 à vocation commerciale, publicitaire ou de propagande quelle qu’elle soit ;

    - 8.1.1.6 à vocation purement polémique ou militante sans la modération raisonnablement attendue ou sans rapport avec le sujet des échanges ;

    - 8.1.1.7 qu’un professionnel de santé normalement éclairé et raisonnable considèrerait comme contraire à l’état de la science ou susceptible de constituer une infox (fake news).

  - 8.1.2 Vous vous engagez également à ne pas inciter une autre personne quelle qu’elle soit à tenir de tels propos.

- 8.2 Contact avec les autres utilisateurs

  - 8.2.1 Nos Services vous permettent d’entrer en contact avec d’autres utilisateurs mais nous ne sommes ni responsables, ni redevable du comportement de ces utilisateurs ou du votre. Nous ne garantissons rien de ce point de vue. Nous attirons votre attention sur le fait que vous restez seul responsable de vos écrits, de vos actes, de vos comportements et de vos choix.

  - 8.2.2 Nos Services n’incluent en aucune manière une quelconque protection ou responsabilité à notre charge concernant votre bonne conduite envers les autres utilisateurs ou les tiers, ni concernant la bonne conduite des autres utilisateurs envers vous.

  - 8.2.3 Vous êtes donc tenu de rester vigilent et agir avec prudence et retenue lorsque vous interagissez virtuellement ou en personne avec d’autres utilisateurs.

  - 8.2.4 Vous vous engagez à ne pas communiquer directement aux autres utilisateurs des informations ou données personnelles vous concernant ni aucun des codes ou information de connexion à votre compte utilisateur pour accéder à nos Services. Vous êtes seul responsable de toute conséquence de votre manquement à ces obligations.

- 8.3 Avis, notes et commentaires

  - 8.3.1 Dans le but d’assurer le meilleur niveau de confiance possible, notre Service propose des fonctionnalités de notation des Quizz. Ces notes sont publiques et visibles par tous.

  - 8.3.2 Par ailleurs, une fonctionnalité de « signalement » est disponible dans l’Application permettant de signaler à Défimédoc tout message ou Contenu paraissant contraire aux CGU.

  - 8.3.3 Pour toutes demandes relatives aux notes, avis et commentaires, vous pouvez nous adresser un mail à ${process.env.REACT_APP_CONTACT_ADDRESS}.

_**9. Exclusion de responsabilité quant aux Contenus en lien avec la santé**_

- 9.1 Défimédoc n’est ni un médecin, ni un pharmacien, ni un professionnel de santé. Les Services constituent un outil d’autoformation et d’information générale. Il ne s’agit en aucun cas d’un outil d’aide au diagnostic, ni d’aide à la prescription, ni d’un dispositif médical.

- 9.2 Les Services permettent à des acteurs du secteur de la pharmacie, du (para)médical, du social ou de la santé d’une façon générale d’entrer en contact, de discuter et d’interagir entre eux.

- 9.3 Il est possible que certains Contenus ne soit pas à jour, insuffisant précis ou qu’ils soient relatifs à des situations particulières qui n’auraient pas été clairement explicitées. Vous restez entièrement responsable de vos actes et en aucun cas un conseil obtenu grâce aux Services, ou via l’interaction avec un autre utilisateur des Services ne doit être suivi sans l’avis d’un professionnel et/ou sous sa seule et entière responsabilité.

- 9.4 Vous êtes tenu de respecter l’ensemble des lois et règlements qui vous sont applicables quelle que soit votre activité en lien avec nos Services ou à l’occasion. Notamment, vous êtes tenu de respecter toute règle applicable au domaine de la prescription médicale, de la dispensation d’ordonnances et de soins ainsi que de médicaments hors ordonnances.


_**10. Sanctions**_

- 10.1 Nous nous réservons le droit à tout moment en cas de violation de l’une quelconque des règles stipulées dans les présentes CGU/CGV, spécialement aux articles 8 et 9 de :

  - 10.1.1 résilier, immédiatement et sans préavis, les présentes CGU ; et/ou

  - 10.1.2 restreindre, limiter, empêcher, supprimer, modifier ou modérer toute information, message, avis, vous concernant ou lié ou émanent de votre compte utilisateur ; et/ou

  - 10.1.3 restreindre, suspendre temporairement ou partiellement votre accès aux Services ; et/ou

  - 10.1.4 mettre un terme définitif, total à votre accès aux Services, à votre compte utilisateur ; et/ou

  - 10.1.5 prendre tout mesure de signalement ou poursuite administrative ou judiciaire à votre encontre.

- 10.2 Nous nous réservons le droit de communiquer à toute autorité compétente dans le cadre d’une procédure amiable ou judiciaire tout éléments vous concernant en notre possession.

_**11. Conditions générales de ventes**_

- 11.1 Généralités

  - 11.1.1 Les présentes conditions générales de vente (CGV) s’appliquent à toute transaction passée entre vous et nous pour tout accès payant à un Service que nous éditons.

  - 11.1.2 Elles complètent les CGU qui restent entièrement applicables ainsi que les lois et règlements français et européens pertinents.

- 11.2 Acceptation

  - 11.2.1 L’acceptation complète et absolue de toutes les conditions des CGV est nécessaire à la réalisation des transactions. Dans le cas où vous n’accepteriez pas l’une de ces clauses, votre commande ne sera pas validée. L’acceptation des CGV est matérialisée concrètement par le fait de cocher la case d’acceptation des CGV prévue à cet effet.

  - 11.2.2 Nous nous réservons le droit de modifier ces CGV. La version applicable des CGV est celle que nous vous communiquons au moment de votre achat par tout moyen ou à défaut celle qui est en ligne au moment de votre achat.

- 11.3 Absence de droit de rétractation

  - 11.3.1 Nous vous informons que dans la mesure où nous vous délivrons des contenus numériques (Services, Application, Contenus) non fournis sur un support matériel et que, lorsque vous achetez ce contenu, vous pouvez l’utiliser immédiatement, vous reconnaissez expressément que, conformément à la législation en vigueur, vous ne pouvez pas bénéficier du droit de rétractation en ce qui concerne ces contenus spécifiques.

- 11.4 Disponibilité et prix

  - 11.4.1 Les Services disponibles à l’achat, leur prix et leurs caractéristiques sont indiqués sur l’Application mobile Défimédoc et sur le site www.defimedoc.fr, une fois connecté à votre compte. Ils sont réservés aux utilisateurs ayant créé un compte utilisateur. Les formules d’abonnement et les tarifs de chaque abonnement sont identiques sur le site et sur l’application. Nous nous réservons le droit de modifier ces éléments à tout moment. Seuls s’appliqueront les tarifs, les conditions et caractéristiques indiqués au moment de la commande. En cas d’utilisation de moyen de paiement non instantané, le tarif applicable est celui en vigueur au moment de la commande effective.

  - 11.4.2 Les prix indiqués sur le site web ou sur tout support que nous contrôlons est indiqué hors frais applicables (mode de paiement particulier, taux de change en fonction de la devise utilisée, etc.).

    Toutes les formules d’abonnement comprennent:

      - L’accès à l’Application Défimédoc pendant toute la durée de l’abonnement.

      - L’accès à tous les Contenus publiés pendant la durée de l’abonnement

      - L’accès aux nouvelles synthèses « L’Essentiel » telle que décrites dans les CGU, et correspondant aux Quiz publiés pendant la durée d’abonnement.

      - L’accès à un espace d’échanges entre apprenants sur les thématiques correspondantes aux Quiz, de façon illimitée.

      - Tous les Quiz et toutes les fiches l’Essentiel publiés depuis la date d’adhésion restent accessibles jusqu’à la date de résiliation de l’abonnement.

      - Toutes les discussions portant sur des thématiques publiées pendant la période d’adhésion restent accessibles jusqu’à la date de résiliation de l’abonnement.

      - Tous les abonnements sont pris pour une durée initiale de un mois ou un an de date à date selon la formule choisie. L’apprenant a immédiatement accès au dernier Quiz publié. Une période d'essai de 14 jours est proposée. Tout abonnement pendant la période d'essai met immédiatement fin à la période d'essai sans report de celle-ci d'aucune sorte. Il n'est proposé qu'une seule période d'essai par utilisateur.

  - 11.4.3 Les prix ne concernent que les Services et en aucun cas les frais d’abonnement et/ou de communication et/ou d’achat et/ou de maintenance des matériels nécessaires à leur utilisation qui restent à votre charge exclusive.

  - 11.4.4 La valeur totale de la commande incluant toutes les taxes applicables ainsi que les frais directement liés à Défimédoc est indiquée avant le paiement.

  - 11.4.5 La validation de la transaction n’est confirmée qu’après le paiement complet du prix.

- 11.5 Paiement

  - 11.5.1 Les moyens de paiement disponibles sont indiqués sur le site web ou sur le support permettant le paiement (notamment écran pertinent de l’Application). Ils sont susceptibles d’évoluer, notamment en raison des pratiques et règles applicables dans certains pays. Nous ne sommes en aucun cas responsables de l’indisponibilité d’un moyen de paiement dans votre pays de résidence ou que vous souhaiteriez utiliser.

  - 11.5.2 Nos Services pourraient intégrer à une date ultérieure des possibilités de paiement via des services de paiement tiers. Si vous avez recours à des services de paiement tiers, d’intermédiaires de paiement ou de paiement en ligne (tels que Paypal® ou des services de e-carte de paiement) vous êtes tenus de vérifier les conditions d’utilisation et les prix d’un tel service. Nous ne sommes pas responsables de ces services tiers.

  - 11.5.3 En tout état de cause, vous garantissez que vous disposez de tous les droits ou autorisations nécessaires pour passer une commande.

  - 11.5.4 Nous ne sommes en aucun cas responsables d’une utilisation frauduleuse de vos modes de paiement ou de votre compte utilisateur.

- 11.6 Modalités d’achat

  - 11.6.1 Toute commande doit être passée via notre site web ou via les magasins d’applications des plateforme iOS et Android.

  - 11.6.2 Durée d’engagement

    La durée d’engagement initiale peut être de un mois ou un an en fonction de la formule d’abonnement choisie. La date de départ de l’engagement est la date de mise à disposition effective de l’Application., Après cette période initiale, le contrat d’abonnement est automatiquement renouvelé par période identique à la période initiale sauf résiliation de votre part au minimum 24 heures avant la date de fin de période d'engagement.

    - Les forfaits mensuels sont prélevés :

      - mensuellement, au premier jour du terme à venir

      - par prélèvement bancaire mensuel si l’abonnement a été contracté sur le site defimedoc.fr

      - par prélèvement via les méthodes de paiement des magasins d’applications en ligne si l’abonnement a été contracté via ses magasins.

    - Les forfaits annuels sont prélevés :

      - annuellement, au premier jour du terme à venir

      - par prélèvement bancaire annuel si l’abonnement a été contracté sur le site defimedoc.fr

      - par prélèvement via les méthodes de paiement des magasins d’applications en ligne si l’abonnement a été contracté via ses magasins.

  - 11.6.3 Offres commerciales

    Défimédoc pourra périodiquement proposer des offres commerciales. Vous en serez informés via nos canaux de communication habituelle.

  - 11.6.4 Remboursement et résiliation

    - 11.6.4.1 Remboursement. En dehors du cas expressément prévu ci-après, vous ne pouvez pas être remboursé du paiement pour l’accès à nos Services. Vous êtes libres d’utiliser ou non l’Application et les Services et le fait que vous décidiez de ne pas utiliser les Services sans résilier votre abonnement ne vous donne droit à aucun remboursement.

    - 11.6.4.2 Résiliation par vous. Vous pouvez résilier votre abonnement d’abonnement à tout moment. La résiliation ne sera effective qu’à l’expiration de la période d’abonnement en cours. Pendant cette période, votre accès aux Services est maintenu et le paiement pour cet accès pour la période en cours nous reste acquis. Une fois l’abonnement effectivement résilié, il n’est plus possible d’accéder aux Contenus ni à l’Application et nous ne recevrons plus de paiement.

    - 11.6.4.3 Résiliation par Défimédoc. Nous nous réservons le le droit de résilier votre abonnement en cas de violation des CGU. En cas d’interruption de l’abonnement du fait de Défimédoc, l’abonnement sera remboursé au proprata du temps réel de mise à disposition de l’application à l’utilisateur.

_**12. Responsabilité et garanties**_

- 12.1 L’utilisation des Services s’effectue sous votre responsabilité et à vos risques et périls. Vous acceptez en conséquence que nous n’apportions, dans les limites de la loi, aucune garantie quant à l’absence d’erreur, de bug ou quant à la conformité à une quelconque attente que vous avez vis-à-vis de nos Services.

- 12.2 Nous fournissons les Services et les Contenus « en l’état », sans aucune garantie expresse ou implicite quelle qu’elle soit. Vous êtes notamment seuls responsables de vous assurer que l’utilisation de nos Services n’est contraire à aucune loi, ni aucun règlement en vigueur dans votre pays de résidence.

- 12.3 Nous ne sommes pas responsables de tout problème technique qui ne serait pas directement lié aux Services.

- 12.4 Vous restez entièrement responsable de toute obligation ou toute formalité de toute nature qui serait utile ou nécessaire à l’utilisation des Services (achats de terminal, création d’un compte permettant l’accès aux magasins d’applications, détention et maintien en vigueur de votre titre professionnel, taxes, acquittement de sommes en tout genre vis-à-vis de tiers tels que des fournisseurs d’accès à internet, etc.).

- 12.5 En tout état de cause, et sous réserve de ce qui précède, dans le cas où notre responsabilité viendrait à être engagée suite à votre réclamation, vous acceptez explicitement que le montant cumulé toutes causes confondues de notre responsabilité ne puisse être supérieur au plus élevé des deux montants suivants :  le montant total que vous nous avez effectivement payé à la date de votre première réclamation.

_**13. Dispositions diverses**_

- 13.1 Il est possible que certains Services ou Contenus nécessitent des conditions particulières qui s’ajouteront à ces CGU/CGV. En cas de conflit, de contradiction ou d’incompatibilité entre les CGU/CGV et certaines clauses particulières, ces dernières prévaudront sur les CGU/CGV.

- 13.2 Nous nous réservons le droit, dans le cas d’une violation de votre part de ces CGU/CGV, de ne pas immédiatement prendre de mesure ou de sanction. Pour autant vous serez toujours considéré comme en violation de ces CGU/CGV et nous conserverons la pleine possibilité de prendre toute mesure ultérieure pour remédier à ces violations.

- 13.3 Dans le cas où une ou plusieurs des conditions des présentes CGU/CGV serai(en)t totalement ou partiellement inapplicable(s), les autres conditions resteraient valables et opposables.

_**14. Abonnement Groupé – Abonnement Principal et Comptes Rattachés**_

- 14.1 Vous avez la possibilité de souscrire à un abonnement groupé (« Abonnement Groupé »), c’est-à-dire à souscrire à un abonnement principal (« Abonnement Principal ») auquel seront rattachés différents autres comptes utilisateurs (« Comptes Rattachés »). Il est entendu que le droit de détenir un Compte Rattaché n’est ouvert qu’à une personne placée sous la responsabilité hiérarchique professionnelle du titulaire de l’Abonnement Principal.

- 14.2 Que vous soyez titulaire d’un Abonnement Principal ou d’un Compte Rattaché, vous êtes tenus de respecter les CGU/CGV. En tant que titulaire d’un Abonnement Principal vous devez vous assurer que l’ensemble des personnes auxquels vous avez ouvert le droit d’obtenir un Compte Rattaché respecte les CGU/CGV.

- 14.3 En cas de faute du titulaire de l’Abonnement Principal ou d’un Compte Rattaché, Défimédoc se réserve le droit d’appliquer toutes les sanctions prévues à l’article 10 des CGU/CGV à tout ou partie des Comptes Rattachés et à l’Abonnement Principal.

- 14.5 Sur le site www.defimedoc.fr, la rubrique « Abonnement » correspond à l’espace de gestion de votre compte sur l’Application permettant notamment de déclarer et gérer les Comptes Rattachés à votre Abonnement Principal.

- 14.6 Sauf accord préalable de Défimédoc, la résiliation ou la suspension de l’Abonnement Principal pour quelque cause que ce soit a pour conséquence automatique, la résiliation ou la suspension des Comptes Rattachés.

- 14.7 Le titulaire de l’Abonnement Principal est seul réputé être l’interlocuteur de Défimédoc pour toute question commerciale relative à un Abonnement Groupé.

_**15. Litige et juridiction compétente**_


- 15.1 Nous mettons le plus grand soin à vous assurer la meilleure expérience possible. Si toutefois vous n’étiez pas satisfait de votre utilisation des Service ou d’un achat réalisé conformément aux CGU/CGV, vous pouvez nous contacter à l’adresse suivante : ${process.env.REACT_APP_CONTACT_ADDRESS}. Nous ferons nos meilleurs efforts pour vous apporter satisfaction.

- 15.2 Par ailleurs, avant toute action en justice ou recours à un tiers, vous vous engagez à tenter de trouver une solution amiable avec nous directement. Nous nous engageons à répondre dans les meilleurs délais et faire nos meilleurs efforts pour trouver une solution acceptable qui protège nos intérêts et les vôtres.

- 15.3 Toute action en justice liée aux présentes CGU/CGV sera soumise au droit français et aux tribunaux compétents de la ville de Paris en France à l’exclusion de toute règle de conflit de loi.

Ceci étant dit et accepté, nous vous souhaitons une agréable expérience Défimédoc.`
        }
    </Markdown>
  </PolicyLayout>

const Title = styled.h1`
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: 900;
  text-align: center;
`

export default TermsOfUse
