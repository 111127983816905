import React, { useState } from 'react'
import { toast } from 'react-toastify'
import ResetPassword from './ResetPassword'
import { resetPassword as RESET_PASSWORD } from 'graphql/mutations/authentication'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { ResetPasswordMutation, ResetPasswordMutationVariables } from '@/types/graphql'

const displayTokenError = () => {
  toast.error('Une erreur est survenue lors du changement de mot de passe. Veuillez refaire une demande de mot de passe oublié', {
    position: 'top-center',
  })
}

const Container = () => {
  const navigate = useNavigate()
  const { token } = useParams()

  const [resetPassword] = useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(RESET_PASSWORD)

  const [form, setForm] = useState({
    password: '',
    secondPassword: '',
  })
  const [errors, setErrors] = useState({})

  const handleChange = (value: string, name: string) => {
    const updatedErrors = { ...errors }

    updateFormValue(value, name)

    setErrors({
      ...updatedErrors,
      [name]: null,
    })
  }

  const updateFormValue = (value: string, name: string) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (token == null) {
      displayTokenError()

      return
    }

    const {
      password,
      secondPassword,
    } = form
    const errors: { [key: string]: string | undefined } = {}
    if (password.length <= 5) {
      errors.password = '6 caractères minimum'
    }
    if (secondPassword.length <= 5) {
      errors.secondPassword = '6 caractères minimum'
    }
    if (password !== secondPassword) {
      errors.secondPassword = 'Les mots de passe ne correspondent pas'
    }

    setErrors(errors)
    const hasError = Object.values(errors).some(error => typeof error === 'string')

    if (!hasError) {
      try {
        const passwordResult = await resetPassword({
          variables: {
            token,
            password,
          },
        })
        toast.success('Le mot de passe a bien été changé', {
          position: 'top-center',
        })
        if (passwordResult.data?.resetPassword === true) {
          navigate('/login')
        }
      } catch (e) {
        displayTokenError()

        if (process.env.NODE_ENV !== 'production') {
          console.warn('GRAPHQL:resetPassword => ', e)
        }
      }
    }
  }

  return (
    <ResetPassword
      errors={ errors }
      form={ form }
      onChange={ handleChange }
      onSubmit={ handleSubmit }
    />
  )
}

export default Container
