import { gql } from '@apollo/client'

export const registration = gql`
  mutation Registration(
    $firstname: String!
    $lastname: String!
    $username: String!
    $password: String!
    $job: String!
    $email: String!
    $birthdate: String
    $rpps: String
    $rppsProof: Upload
    $organization: String
    $invitationToken: String
  ) {
    registration(
      firstname: $firstname,
      lastname: $lastname,
      username: $username,
      password: $password,
      job: $job,
      email: $email,
      birthdate: $birthdate,
      rpps: $rpps,
      rppsProof: $rppsProof,
      organization: $organization,
      invitationToken: $invitationToken
    )
  }
`
