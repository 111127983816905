import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import PartnerCard from 'components/landing/TrustGrouping/PartnerCard'

const trustList = [
  {
    image: require('assets/images/consortium/mfo.png'),
    url: 'https://www.maformationofficinale.com/pages/cetait-une-promesse-profitez-maintenant-du-nouveau-partenariat-ma-formation-officinale-x-defimedoc',
  },
  {
    image: require('assets/images/consortium/giropharm.png'),
    url: 'https://www.giropharm.fr/',
  },
  {
    image: require('assets/images/consortium/fspf.png'),
    url: 'http://www.fspf.fr',
  },
  {
    image: require('assets/images/consortium/uspo.png'),
    url: 'https://uspo.fr',
  },
  {
    image: require('assets/images/consortium/24-7.png'),
    url: 'https://www.24-7services.eu/',
  },
  {
    image: require('assets/images/consortium/giphar.png'),
    url: 'https://www.giphar.fr/',
  },
  {
    image: require('assets/images/consortium/pharmupp.png'),
    url: 'https://www.pharm-upp.fr/',
  },
  {
    image: require('assets/images/consortium/capunipharm.png'),
    url: 'http://www.capunipharm.fr/',
  },
  {
    image: require('assets/images/consortium/ceido.png'),
    url: 'https://www.ceido.com/',
  },
  {
    image: require('assets/images/consortium/totum6.png'),
    url: 'https://www.totum.fr/',
  },
  {
    image: require('assets/images/consortium/act.png'),
    url: 'https://actpharmacie.fr/',
  },
  {
    image: require('assets/images/consortium/dephie.png'),
    url: 'https://www.dephie.com/',
  },
  {
    image: require('assets/images/consortium/epitop.png'),
    url: 'https://epitop.fr/',
  },
  {
    image: require('assets/images/consortium/oxygenpharma.png'),
    url: 'https://www.oxygen-pharma.fr/',
  },
  {
    image: require('assets/images/consortium/pharma2m.png'),
    url: 'https://pharma2m.fr/',
  },
  {
    image: require('assets/images/consortium/anepf.png'),
    url: 'https://anepf.org/',
  },
  {
    image: require('assets/images/consortium/37dms.png'),
    url: 'https://37degres-masante.fr',
  },
  {
    image: require('assets/images/consortium/teampharma1.png'),
    url: 'https://www.groupement-team-pharma.com/',
  },
  {
    image: require('assets/images/consortium/synaps.png'),
    url: 'https://www.synaps-pharma.com/',
  },
]

const TrustGrouping: FC = () =>
  <>
    <Title>
      Ils nous font&nbsp;
      <TitleColored>confiance</TitleColored>
    </Title>
    <TrustList>
      { trustList.map((info, i) =>
        <Wrapper key={ i }>
          <PartnerCard
            image={ info.image }
            url={ info.url }
          />
        </Wrapper>,
      )}
    </TrustList>
  </>

const Title = styled.h2`
  font-family: "Brother 1816";
  font-size: 2.25rem;
  font-style: bold;
  font-weight: bold;
  color: ${colors.midnightBlue};
  text-align: center;
`

const TitleColored = styled.span`
  color: ${colors.lightGreen};
`

const TrustList = styled.ul`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0 2rem 20rem;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25.438rem;
  height: 10.625rem;
  margin: 1rem;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 0.188rem 0.75rem 0 rgb(0 0 0 / 12%);
`

export default TrustGrouping
