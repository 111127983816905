import React, { CSSProperties, useCallback } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import DropdownIndicator from 'components/atoms/FormSelect/DropdownIndicator'
import Icon from 'components/atoms/Icon'

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  NUMBER = 'number',
  DECIMAL = 'decimal',
  DATE = 'date',
}

type CommonInputProps<T extends string> = {
  id: string;
  name: T;
  error?: string;
  placeholder?: string;
  underline?: boolean;
  disabled?: boolean;
}

type InputProps<T extends string> = CommonInputProps<T> & {
  type: InputType;
  maxLength?: number;
  icon?: string;
  onIconClick?: () => void;
  value?: string | number | null;
  max?: string;
  onChangeText: (value: string, field: T) => void;
}

type SelectorInputProps<T extends string> = CommonInputProps<T> & {
  value?: string | null
  style?: CSSProperties;
  dropdownIndicatorStyle?: CSSProperties;
  type: 'select'
  isOpen?: boolean;
  'aria-labelledby': string;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

type Props<T extends string> = InputProps<T> | SelectorInputProps<T>;

const sanitizeValue = (value?: string | number | null, type?: InputType) => {
  if (value == null) {
    return ''
  }

  if (type !== 'number' && type !== 'decimal') {
    return value
  }

  return value.toString()
}

const Input = <T extends string>(props: Props<T>) => {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.type === 'select' || !props.onChangeText) {
      return
    }

    const value = props.maxLength != null && e.target.value.length > props.maxLength
      ? e.target.value.slice(0, props.maxLength)
      : e.target.value

    props.onChangeText(value, props.name)
  }, [props])

  if (props.type === 'select') {

    return (
      <StyledSelector
        $disabled={ props.disabled }
        $error={ props.error }
        aria-haspopup='listbox'
        aria-labelledby={ props['aria-labelledby'] }
        id={ props.id }
        role="button"
        style={ props.style }
        tabIndex={ props.disabled ? undefined : 0 }
        onClick={ props.onClick }
        onKeyDown={ props.onKeyDown }
      >
        { props.placeholder && !props.value
          ? <PlaceHolder>{ props.placeholder }</PlaceHolder>
          : <Value>{ props.value }</Value>
        }
        <DropdownIndicator
          isOpen={ props.isOpen }
          style={ props.dropdownIndicatorStyle }
        />
      </StyledSelector>
    )
  }

  return (
    <>
      <StyledInput
        $error={ props.error }
        $hasIcon={ props.icon != null }
        disabled={ props.disabled }
        id={ props.id }
        placeholder={ props.placeholder }
        type={ props.type }
        value={ sanitizeValue(props.value, props.type) }
        max={ props.max }
        onChange={ handleChange }
      />
      {
        props.icon != null && (
          <StyledIcon
            name={ props.icon }
            size={ 24 }
            stroke={ colors.mediumGray }
            strokeWidth={ 10 }
            onClick={ () => props.onIconClick?.() }
          />
        )
      }
    </>
  )
}

type StyledInputProps = {
  $error?: string ;
  $hasIcon?: boolean;
}

type StyledSelectorProps = {
  $disabled?: boolean;
  $error?: string;
}

const StyledInput = styled.input<StyledInputProps>`
  border-top: ${({ $error }) => $error ? `1px solid ${colors.red}` : `1px solid ${colors.lightGreen}`};
  transition: border-top 300ms ease-in-out;
  ${({ $hasIcon }) => $hasIcon && `
    box-sizing: border-box;
    max-width: 100%;
    padding-right: 2.5rem;
  `}
`

const StyledSelector = styled.div<StyledSelectorProps>`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  height: 2.8rem;
  padding: 0 0 0 1.125rem;
  margin-bottom: 1rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  line-height: 2.8rem;
  color: ${({ $disabled }) => $disabled ? '#9a9a9a' : 'inherit'};
  background-color: ${({ $disabled }) => $disabled ? '#f5f5f5' : colors.white};
  border-top: 1px solid ${colors.lightGreen};
  border-top: ${({ $error }) => $error ? `1px solid ${colors.red}` : `1px solid ${colors.lightGreen}`};
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 18%);
  transition: border-top 300ms ease-in-out;
`

const Value = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PlaceHolder = styled.div`
  overflow: hidden;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.mediumGray};
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.5;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  cursor: pointer;
`

export default Input
