import React, { useMemo } from 'react'
import { logError } from 'logService'
import { toast } from 'react-toastify'
import { registration as REGISTRATION } from 'graphql/mutations/register'
import { ApolloError, ServerError, useMutation, useQuery } from '@apollo/client'
import { AllJobsQuery, RegistrationMutation } from '@/types/graphql'
import styled from 'styled-components'
import { colors } from 'theme'
import { Button, FormFilePicker, FormInput, FormPasswordInput, FormSelect } from 'components/atoms'
import Aknowledgement from 'components/forms/Aknowledgement'
import zod from 'zod'
import useForm from 'hooks/useForm'
import { invalidField, mandatoryField, requireTosValidation } from 'constants/form'
import { isEmail } from 'utils/regex'
import { ALL_JOBS } from 'graphql/queries/jobs'
import { LOGIN_PATH } from 'components/Router'
import { useNavigate, useParams } from 'react-router-dom'
import { InputType } from 'components/atoms/Input'
import Form from 'components/atoms/Form'
import DataCollectionInfo from 'components/atoms/DataCollectionInfo'

const schema = zod.object({
  lastname: zod.string().min(1, mandatoryField),
  firstname: zod.string().min(1, mandatoryField),
  username: zod.string().min(1, mandatoryField),
  job: zod.string().min(1, mandatoryField),
  email: zod.string().regex(isEmail, invalidField).min(1, mandatoryField),
  password: zod.string().min(6, '6 caractères minimum').min(1, mandatoryField),
  birthdate: zod.string().nullish(),
  rpps: zod.string().nullish(),
  rppsProof: zod.instanceof(File).nullish(),
  organization: zod.string().nullish(),
  aknowledged: zod.boolean().refine(
    (value) => value === true, requireTosValidation,
  ),
}).superRefine((value, context) => {
  if (value.birthdate && new Date(value.birthdate) >= new Date()) {
    context.addIssue({
      code: 'custom',
      message: 'Champ invalide',
      path: ['birthdate'],
    })
  }

  if (!value.rpps && value.rppsProof == null) {
    return context.addIssue({
      code: 'custom',
      message: 'Un numéro RPPS ou un justificatif est requis',
      path: ['rpps', 'rppsProof'],
    })
  }

  if (value.rpps && value.rpps.length < 11) {
    return context.addIssue({
      code: 'custom',
      message: 'Le numéro RPPS doit contenir 11 caractères',
      path: ['rpps'],
    })
  }

  return context
})

type RegisterForm = zod.infer<typeof schema>

const initialState: RegisterForm = {
  lastname: '',
  firstname: '',
  username: '',
  job: '',
  email: '',
  password: '',
  birthdate: undefined,
  rpps: undefined,
  rppsProof: undefined,
  organization: undefined,
  aknowledged: false,
}

const Register = () => {
  const { invitationToken } = useParams()

  const [registration] = useMutation<RegistrationMutation>(REGISTRATION)
  const navigate = useNavigate()

  const allJobs = useQuery<AllJobsQuery>(ALL_JOBS)

  const jobsOptions = useMemo(() => {
    const jobs = allJobs.data?.allJobs?.map((job) => job.title) ?? []

    return jobs.map((job) => ({
      label: job,
      value: job,
    }))
  }, [allJobs.data])

  const registerSubmit = async (form: RegisterForm) => {
    try {
      await registration({
        variables: {
          ...form,
          invitationToken: invitationToken,
          job: form.job,
        },
      })

      toast.success(`Un email vous a été envoyé sur l'adresse ${form.email}`, {
        position: 'top-center',
      })

      navigate(LOGIN_PATH)
    } catch (e) {
        type Traduction = {
          [key: string]: string
        }

        if (e instanceof ApolloError) {
          const networkError = e.networkError as ServerError
          const traduction: Traduction = {
            'This email is already used.': 'Cet email est déjà utilisé !',
            'This username is already used.': 'Ce pseudo est déjà utilisé !',
            'this file is too big.': 'Le justificatif est trop volumineux !',
          }

          const message = typeof networkError?.result === 'string'
            ? traduction[networkError?.result] || 'Une erreur est survenue'
            : traduction[networkError?.result?.errors[0]?.message] || 'Une erreur est survenue'

          toast.error(message, {
            position: 'top-center',
          })

          logError('GRAPHQL', 'register', e)
        }

        throw e
    }
  }

  const [data, errors, handleChange, handleSubmit] = useForm(initialState, schema, registerSubmit)

  const selectedJobOption = useMemo(() => jobsOptions.find((jobOption) => jobOption.value === data.job), [data.job, jobsOptions])

  return (
    <Form
      autoComplete="off"
      onSubmit={ handleSubmit }
    >
      <FormInput
        error={ errors.lastname }
        isRequired
        label="Nom"
        name="lastname"
        type={ InputType.TEXT }
        value={ data.lastname }
        onChangeText={ handleChange }
      />
      <FormInput
        error={ errors.firstname }
        isRequired
        label="Prénom"
        name="firstname"
        type={ InputType.TEXT }
        value={ data.firstname }
        onChangeText={ handleChange }
      />
      <FormInput
        error={ errors.username }
        isRequired
        label="Pseudo"
        name="username"
        type={ InputType.TEXT }
        value={ data.username }
        onChangeText={ handleChange }
      />
      <FormInput
        error={ errors.birthdate }
        label="Date de naissance"
        name="birthdate"
        type={ InputType.DATE }
        value={ data.birthdate }
        max={ new Date().toISOString().split('T')[0] }
        onChangeText={ handleChange }
      />
      <InformativeText>
        {'Votre date de naissance est nécessaire si vous souhaitez obtenir une attestation DPC.'}
      </InformativeText>
      <FormSelect
        error={ errors.job }
        isRequired
        label="Profession"
        name="job"
        options={ jobsOptions }
        selectedOption={ selectedJobOption }
        onChange={ handleChange }
      />
      <FormInput
        error={ errors.email }
        isRequired
        label="Email"
        name="email"
        type={ InputType.EMAIL }
        value={ data.email }
        onChangeText={ handleChange }
      />
      <FormPasswordInput
        error={ errors.password }
        isRequired
        label="Mot de passe"
        name="password"
        value={ data.password }
        onChangeText={ handleChange }
      />
      <FormInput
        error={ errors.organization }
        label="Groupement"
        name="organization"
        type={ InputType.TEXT }
        value={ data.organization }
        onChangeText={ handleChange }
      />
      <RppsProofsTitle>
        Justificatif *
      </RppsProofsTitle>
      <FormInput
        error={ errors.rpps }
        label="N° RPPS"
        maxLength={ 11 }
        name="rpps"
        type={ InputType.NUMBER }
        value={ data.rpps }
        onChangeText={ handleChange }
      />
      <RppsProofsEither>
        Ou
      </RppsProofsEither>
      <InformativeText>
        {'Si vous n\'avez pas de numéro RPPS, merci de télécharger un justificatif professionnel ou d\'étudiant en santé : carte de l\'Ordre, copie de diplôme, carte d\'étudiant…'}
      </InformativeText>
      <FormFilePicker
        error={ errors.rppsProof }
        file={ data.rppsProof }
        label="N° RPPS"
        name="rppsProof"
        onChange={ handleChange }
      />
      <AknowLedgmentWrapper>
        <Aknowledgement
          aknowledged={ data.aknowledged }
          error={ errors.aknowledged }
          onChange={ handleChange }
        />
      </AknowLedgmentWrapper>
      <DataCollectionInfo />
      <SubscribeButton
        size="large"
        type="submit"
        variant="primary"
      >
        {'Je m\'inscris'}
      </SubscribeButton>
    </Form>
  )
}

const RppsProofsTitle = styled.span`
  margin-top: 1.3rem;
  margin-bottom: 1.4rem;
  font-family: 'Brother 1816';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.midnightBlue};
`

const RppsProofsEither = styled.span`
  margin-bottom: 1rem;
  font-family: 'Brother 1816';
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 800;
  color: ${colors.midnightBlue};
`

const InformativeText = styled.p`
  margin: 0 0 0.375rem;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1rem;
  color: ${colors.midnightBlue};
`

const AknowLedgmentWrapper = styled.div`
  margin-top: 3.25rem;
`

const SubscribeButton = styled(Button)`
  margin-top: 1.625rem;
`

export default Register
