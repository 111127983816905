import React from 'react'
import styled from 'styled-components'
import NavItem from './NavItem'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { useQuery } from '@apollo/client'

const HeaderNav = () => {
  const userQuery = useQuery(SUBSCRIPTION)
  const { pathname } = window.location
  const subRouteName = pathname.substring(pathname.lastIndexOf('/') + 1)
  const meId = userQuery?.data?.me?.id
  const subOwnerId = userQuery?.data?.me?.subscription?.user?.id
  const hasTeamMenu = meId !== null && meId === subOwnerId

  return (
    <NavItems>
      <NavItem
        href="/me/profile"
        isCurrent={ subRouteName !== 'subscription'
            && subRouteName !== 'upgrade'
            && subRouteName !== 'change-password'
            && subRouteName !== 'my-team' }
      >
        Profil
      </NavItem>
      <NavItem
        href="/me/change-password"
        isCurrent={ subRouteName === 'change-password' }
      >
        Mot de passe
      </NavItem>
      { hasTeamMenu && userQuery?.data?.me?.subscription.plan.isTeam &&
      <NavItem
        href="/me/my-team"
        isCurrent={ subRouteName === 'my-team' }
      >
        Équipe
      </NavItem>
      }
      { userQuery?.data?.me?.subscription != null
        &&
          <NavItem
            href="/me/subscription"
            isCurrent={ subRouteName === 'subscription' || subRouteName === 'upgrade' }
          >
            Abonnement
          </NavItem>
      }
    </NavItems>
  )
}

const NavItems = styled.ul`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin: 0;

  @media (width <= 1090px) {
    display: none;
  }
`

export default HeaderNav
