import React, { useContext } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import SubscriptionCard from './utils/SubscriptionCard'
import { DrawerContext } from 'context/Drawer'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { AVAILABLE_PLANS } from 'graphql/queries/plan'
import { colors } from 'theme'

const Subscriptions = isOwner => {
  const { actions } = useContext(DrawerContext)
  const { setPlan } = actions

  const availablePlansQuery = useQuery(AVAILABLE_PLANS)
  const currentSubscriptionQuery = useQuery(SUBSCRIPTION)

  if (availablePlansQuery.loading || currentSubscriptionQuery.loading) {
    return null
  }

  return (
    <Wrapper >
      { isOwner ?
        <>
          <Title >
            LES AUTRES FORMULES
          </Title>
          <Description >
            Si vous choisissez un nouvel abonnement, il remplacera immédiatement votre abonnement en cours.
            Le prorata de votre ancien abonnement non consommé sera déduit du prix de votre nouvel abonnement.
          </Description>
          <SubscriptionsList>
            {availablePlansQuery.data.availablePlans
              .filter(plan => plan.id !== currentSubscriptionQuery.data.me.subscription.plan.id)
              .map((plan, key) =>
                <li key={ key }>
                  <SubscriptionCard
                    plan={ plan }
                    onClick={ () => setPlan(plan) }
                  />
                </li>,
              )
            }
          </SubscriptionsList>
        </>
        : null }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  margin-bottom: 1rem;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.darkGray};
  opacity: 0.5;
`

const Description = styled.span`
  margin-bottom: 1rem;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.darkGray};
  white-space: pre-wrap;
`
const SubscriptionsList = styled.ul`
  display: block;
  flex-shrink: 0;
  flex-wrap: wrap;
`

export default Subscriptions
