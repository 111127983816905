import React, { FC, useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { CartContext } from 'context/Cart'
import DrawerBottom from './DrawerBottom'
import { MySubscriptionQuery, Plan } from '@/types/graphql'

const MINIMUM_TEAM_LICENSES = 2

const Container: FC<{
  plan: Plan
}> = ({
  plan,
}) => {
  const navigate = useNavigate()

  const [licencesCountInit, setLicencesCountInit] = useState(false)
  const [minLicencesNumber, setMinLicencesNumber] = useState(MINIMUM_TEAM_LICENSES)
  const [form, setForm] = useState({
    licencesCount: MINIMUM_TEAM_LICENSES,
    coupon: null,
  })

  const { actions } = useContext(CartContext)

  useQuery<MySubscriptionQuery>(SUBSCRIPTION, {
    onCompleted: data => {
      if (!data?.me?.subscription || licencesCountInit) {
        return
      }

      if (data.me.subscription.quantity <= MINIMUM_TEAM_LICENSES) {
        return
      }

      setForm({
        ...form,
        licencesCount: data.me.subscription.quantity,
      })
      setMinLicencesNumber(data.me.subscription.quantity)
      setLicencesCountInit(true)
    },
  })

  const handleChange = (value: string, name: string) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    const licencesCountValue = plan.metadata.threshold === 1 ? 1 : form.licencesCount

    actions.setCart({
      licencesCountValue,
      plan,
    })

    window.scrollTo(0, 0)

    navigate('/payment')
  }

  return (
    <DrawerBottom
      form={ form }
      minLicencesNumber={ minLicencesNumber }
      plan={ plan }
      onChange={ handleChange }
      onSubmit={ handleSubmit }
    />
  )
}

export default Container
