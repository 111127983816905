export const giphar = {
  consortium: {
    name: 'Giphar',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
      { amount: '-30%', nbLicences: '20', description: 'à partir de 20 licences achetées' },
    ],
  },
}

export const fspf = {
  consortium: {
    name: 'FSPF',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const giropharm2 = {
  consortium: {
    name: 'Giropharm',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const uspo = {
  consortium: {
    name: 'USPO',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const teams = {
  consortium: {
    name: 'Teams',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const labofficine = {
  consortium: {
    name: 'Lab\'Officine',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-20%', nbLicences: '4', description: 'à partir de 4 licences achetées' },
      { amount: '-30%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const ubipharm = {
  consortium: {
    name: 'Ubipharm',
    descriptions: [
      { message: 'UBIPHARM vous offre un an d\'abonnement gratuit à Défimédoc* !' },
      { message: 'Laissez-nous vos coordonnées, nous prendrons contact avec vous pour mettre en place votre abonnement.' },
      { message: '* sous réserve des conditions générales d\'adhésion à UBIPHARM.' },
    ],
  },
}

export const vingtQuatreSept = {
  consortium: {
    name: '24-7 Services',
    trialPeriod: '30 jours d\'essai gratuit sans engagement',
    discounts: [
      { oldPrice: '11,99€', newPrice: '10,99€/mois', type: 'fix' },
    ],
  },
}

export const clubDuComptoir = {
  consortium: {
    name: 'Club du comptoir',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-20%', nbLicences: '2', description: 'sur les équipes de 2 à 9 licences' },
      { amount: '-30%', nbLicences: '10', description: 'sur les équipes de 10 licences et plus' },
    ],
  },
}

export const totum6 = {
  consortium: {
    name: 'Totum',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const pharmUppEssential = {
  consortium: {
    name: 'Pharm-UPP Essentiel',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const pharmUppUltra = {
  consortium: {
    name: 'Pharm-UPP ULTRA',
    descriptions: [
      { message: 'Vous avez intégré le programme ULTRA, votre abonnement est directement pris en charge par Pharm-UPP, pour vous et votre équipe.' },
      { message: 'Pour valider votre compte, votre Carte Bancaire est nécessaire.' },
      { message: 'Aucun débit ne sera effectué sur cette carte.' },
    ],
  },
}

export const soloDefimedoc = {
  consortium: {
    name: 'Solo Défimedoc',
    oldPeriod: '14 jours',
    newPeriod: '1 mois',
    periodDescription: 'd\'essai gratuit sans engagement',
    trialPeriod: '1 mois d\'essai gratuit sans engagement',
    discounts: [
      { price: '11,99€/mois', type: 'solo' },
    ],
  },
}

export const pharmaConsulting = {
  consortium: {
    name: 'Pharma Consulting',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const capUnipharm = {
  consortium: {
    name: 'Cap Unipharm',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const urpsPharmaciens = {
  consortium: {
    name: 'URPS Pharmaciens Ile-de-France',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const dephie = {
  consortium: {
    name: 'Déphie',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const ceido = {
  consortium: {
    name: 'Ceido',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const act = {
  consortium: {
    name: 'Act Pharmacie',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
      { amount: '-30%', nbLicences: '20', description: 'à partir de 20 licences achetées' },
    ],
  },
}

export const sponsoring = {
  consortium: {
    name: 'Parrainage',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const oxygenpharma = {
  consortium: {
    name: 'Oxygen\'Pharma',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
      { amount: '-30%', nbLicences: '20', description: 'à partir de 20 licences achetées' },
    ],
  },
}

export const pharma2m = {
  consortium: {
    name: 'Pharma2M',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
      { amount: '-30%', nbLicences: '20', description: 'à partir de 20 licences achetées' },
    ],
  },
}

export const settings37dms = {
  consortium: {
    name: '37DMS',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const teampharma1 = {
  consortium: {
    name: 'Team Pharma',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}

export const MFO = {
  consortium: {
    name: 'Ma Formation Officinale',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '20% de remise', nbLicences: '2', description: 'dès 2 licences achetées' },
      { amount: '30% de remise', nbLicences: '3', description: 'dès 3 licences achetées' },
      { amount: '29,99€', nbLicences: '5', description: 'dès 5 licences achetées -', specialMarketing: 'forfait licences illimitées' },
    ],
  },
}

export const synaps = {
  consortium: {
    name: 'Synaps',
    abonnementType: 'Team mensuel',
    trialPeriod: '2 mois d\'essai gratuit sans engagement',
    discounts: [
      { amount: '-10%', nbLicences: '3', description: 'à partir de 3 licences achetées' },
      { amount: '-15%', nbLicences: '5', description: 'à partir de 5 licences achetées' },
      { amount: '-20%', nbLicences: '10', description: 'à partir de 10 licences achetées' },
    ],
  },
}
