import React, { useState, useEffect, useCallback } from 'react'
import { logError } from 'logService'
import ProfileForm from './ProfileForm'
import { toast } from 'react-toastify'
import { isEmail } from 'utils/regex'
import { UPDATE_MY_PROFILE } from 'graphql/mutations/user'
import { mandatoryField, invalidField } from 'constants/form'
import { DEFAULT_COUNTRY } from 'constants/countries'
import { useQuery, useMutation } from '@apollo/client'
import { MeQuery, UpdateMyProfileMutation } from '@/types/graphql'
import { USER } from 'graphql/queries/user'

const Container = () => {
  const userQuery = useQuery<MeQuery>(USER)
  const [updateMyProfile] = useMutation<UpdateMyProfileMutation>(UPDATE_MY_PROFILE)

  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [form, setForm] = useState<{
    lastname: string
    firstname: string
    username: string
    birthdate: string
    email: string
    address: string
    address2: string
    city: string
    zipCode: string
    job: string
    country: string
    rpps: string
  }>({
    lastname: '',
    firstname: '',
    username: '',
    birthdate: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    zipCode: '',
    job: '',
    country: DEFAULT_COUNTRY,
    rpps: '',
  })
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [editionMode, setEditionMode] = useState<boolean>(false)

  const fillForm = useCallback(() => {
    const { me } = userQuery?.data ?? {}

    if (me != null) {
      setForm({
        username: me.username ?? '',
        lastname: me.lastname ?? '',
        firstname: me.firstname ?? '',
        birthdate: me.birthdate ?? '',
        email: me.email ?? '',
        job: me.job ?? '',
        address: me.address?.address ?? '',
        address2: me.address?.address2 ?? '',
        city: me.address?.city ?? '',
        zipCode: me.address?.zipCode ?? '',
        country: me.address?.country ?? DEFAULT_COUNTRY,
        rpps: me.rpps ?? '',
      })
    }

    setInitialLoad(false)
  }, [form, userQuery])

  useEffect(() => {
    if (!initialLoad) {
      return
    }

    if (undefined !== userQuery?.data?.me) {
      fillForm()
    }
  }, [initialLoad, userQuery, form, fillForm])

  const handleChange = (value: string, name: string) => {
    setEditionMode(true)
    const { [name]: _removedElement, ...updatedErrors } = errors

    updateFormValue(value, name)

    setErrors(() => ({ ...updatedErrors }))
  }

  const updateFormValue = (value: string, name: string) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, formData: Record<string, string>) => {
    e.preventDefault()

    const {
      username,
      lastname,
      firstname,
      birthdate,
      email,
      address,
      address2,
      city,
      zipCode,
      country,
      job,
      rpps,
    } = formData

    const requiredFields = {
      lastname,
      firstname,
      username,
      email,
      job,
    }

    const updatedErrors = { ...errors }

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (key === email && value.length !== 0 && !isEmail.test(value)) {
        updatedErrors[key] = invalidField
      }

      if (value.length === 0) {
        updatedErrors[key] = mandatoryField
      }
    })

    if (birthdate !== null && new Date(birthdate) >= new Date()) {
      updatedErrors.birthdate = invalidField
    }

    if (rpps !== null && rpps.length !== 0 && rpps.length !== 11) {
      updatedErrors.rpps = invalidField
    }

    setErrors(() => ({ ...updatedErrors }))

    const hasError = Object.values(updatedErrors).some(error => typeof error === 'string')

    if (!hasError) {
      try {
        await updateMyProfile({
          variables: {
            user: {
              username,
              lastname,
              firstname,
              birthdate,
              email,
              job: job,
              rpps,
              address: {
                address,
                address2,
                city,
                zipCode,
                country: country,
              },
            },
          },
          refetchQueries: [{
            query: USER,
          }],
          awaitRefetchQueries: true,
        })
        setEditionMode(false)

        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }
      } catch (e) {
        toast.error('Oups, une erreur est survenue', {
          position: 'top-center',
        })
        logError('GRAPHQL', 'updateMyProfile', e)
      }
    }
  }

  const handleCancel = () => {
    setEditionMode(false)
    setErrors({})
    fillForm()
  }

  return (
    <ProfileForm
      editionMode={ editionMode }
      errors={ errors }
      form={ form }
      onCancel={ handleCancel }
      onChange={ handleChange }
      onSubmit={ handleSubmit }
    />
  )
}

export default Container
