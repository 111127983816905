import { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const DiscountBanner = ({
  title,
  description,
  isOnDrawer,
}) => {
  const [contentHeight, setContentHeight] = useState(undefined)
  const contentRef = useRef(null)

  useEffect(() => {
    setContentHeight(contentRef.current?.clientHeight)
  }, [contentRef.current?.clientHeight])

  return (
    <Wrapper $isOnDrawer={ isOnDrawer }>
      <svg
        height={ `${contentHeight}px` }
        preserveAspectRatio='none'
        viewBox="0 0 257 123"
        width="300px"
      >
        <defs>
          <linearGradient
            gradientTransform="matrix(337.65, 0, 0, -82, 158223.26, 44958)"
            gradientUnits="userSpaceOnUse"
            id="gradient"
            x1="-468.93"
            x2="-467.52"
            y1="547.8"
            y2="547.74"
          >
            <stop
              offset="0"
              stop-color="#23bcba"
            />
            <stop
              offset="1"
              stop-color="#00e5a6"
            />
          </linearGradient>
        </defs>
        <path
          d="M0 0H251.972C254.834 0 256.77 2.91754 255.658 5.55435L232.696 60L255.795 117.509C256.85 120.137 254.915 123 252.083 123H0V0Z"
          fill="url(#gradient)"
        />
      </svg>
      <Content $isOnDrawer={ isOnDrawer }
        ref={ contentRef }
      >
        <Title>
          {title}
        </Title>
        <Description>
          {description.map((text, key) =>
            <Fragment key={ key }>
              <span>{text}</span>
              <br />
            </Fragment>,
      )}
        </Description>
      </Content>
    </Wrapper>)
}

DiscountBanner.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOnDrawer: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

DiscountBanner.defaultProps = {
  isOnDrawer: false,
}

const Wrapper = styled.div`
  position: relative;
  line-height: 1.375rem;
  ${({ $isOnDrawer }) => $isOnDrawer
    ? `
      right: 3rem;
      margin: -2.5rem 0 1.5rem 0;
    ` : `
      margin-top: -2rem;
      margin-left: -2rem;
    `
}
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  padding: 0.7rem;
  ${({ $isOnDrawer }) => $isOnDrawer && 'padding-left: 1.3rem;'}
`

const Title = styled.span`
  font-family: 'Brother 1816';
  font-weight: 800;
  color: ${colors.white};
`

const Description = styled.span`
  font-family: 'Brother 1816';
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.white};
`
export default DiscountBanner
