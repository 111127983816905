import { Coupon, PlanDiscount, PlanDiscountLevel } from '@/types/graphql'
import { getApplicableDiscountLevel } from './plans'

export const format = (price: number) => {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return formatter.format(price)
}

interface CalculatePriceParams {
  amount: number
  quantity: number
  planDiscount?: PlanDiscount | null
  coupon: Coupon | null
}

interface CalculatePriceResult {
  subTotal: number
  tax: number
  totalTTC: number
  hasApplicableDiscountLevel: boolean
  applicableDiscountLevel: PlanDiscountLevel | undefined
  discount: number
}

export const calculatePrice = ({
  amount,
  quantity,
  planDiscount,
  coupon,
}: CalculatePriceParams): CalculatePriceResult => {
  const priceWithoutDiscount = (amount / 100) * quantity
  const hasDiscount = planDiscount?.levels != null
  const applicableDiscountLevel = hasDiscount
    ? getApplicableDiscountLevel(planDiscount.levels, quantity)
    : undefined
  const hasApplicableDiscountLevel = applicableDiscountLevel !== undefined

  let discount = 0
  if (coupon) {
    discount = coupon.percentOff ? priceWithoutDiscount * coupon.percentOff : 0
    discount = Number(discount.toFixed(2))
  }

  const subTotal = applicableDiscountLevel?.fixedPrice ? (applicableDiscountLevel.fixedPrice / 100) : (priceWithoutDiscount - discount)

  let discountAmount = 0
  if (hasApplicableDiscountLevel && !applicableDiscountLevel?.fixedPrice) {
    discountAmount = subTotal * (applicableDiscountLevel.percentOff / 100)
  }
  discountAmount = Number(discountAmount.toFixed(2))

  const totalAfterDiscount = subTotal - discountAmount

  const VAT = 0.2
  const tax = Number((totalAfterDiscount * VAT).toFixed(2))

  const totalTTC = totalAfterDiscount + tax

  return {
    subTotal: Number(subTotal.toFixed(2)),
    tax,
    totalTTC: Number(totalTTC.toFixed(2)),
    hasApplicableDiscountLevel,
    applicableDiscountLevel,
    discount,
  }
}
